import React from 'react'
import { TablePagination } from '@mui/material'
import { Pager } from './types'
import { useTranslation } from 'react-i18next'

const styles = {
  root: {
    border: '0',
    fontSize: '13px',
    color: '#474747',
    fontWeight: 'normal',
    fontFamily: 'Montserrat-Regular, sans-serif',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    '& > *': {
      margin: 'auto',
    },
    '& .MuiTablePagination-caption': {
      fontSize: '13px',
      fontWeight: 'normal',
      fontFamily: 'Montserrat-Regular, sans-serif',
      color: '#474747',
    },
  },

  label: {
    fontWeight: 'normal',
    fontFamily: 'Montserrat-Regular, sans-serif',
    fontSize: '13px',
    color: '#474747',
  },
}

const rowsPerPageOptions = [10, 20, 50, 100]

type PaginationProps = {} & Pager

export function Pagination(props: PaginationProps) {
  const { t } = useTranslation()

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    props.rowsPerPage || rowsPerPageOptions[0]
  )

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.handleChangeRowsPerPage) {
      setRowsPerPage(parseInt(event.target.value))
      props.handleChangeRowsPerPage(event)
    }
  }

  return (
    <TablePagination
      labelDisplayedRows={({ from, to, count }) => ''}
      labelRowsPerPage={t('resultsPerPage')}
      count={props.count}
      onPageChange={props.handleChangePage}
      page={props.page}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      slotProps={{
        select: {
          SelectDisplayProps: { style: { borderRadius: '3px', border: 'none' } },
        },
        actions: {
          previousButton: {
            style: {
              color: props.page === 0 ? '#b5b8c4' : '#ADB84E',
              border: 'none',
              borderRadius: '3px',
              width: '30px',
              height: '30px',
            },
          },
          nextButton: {
            style: {
              color: props.count - (props.page + 1) * rowsPerPage <= 0 ? '#b5b8c4' : '#ADB84E',
              border: 'none',
              borderRadius: '3px',
              width: '30px',
              height: '30px',
            },
          },
        },
      }}
      sx={{
        border: 'none',
        '& .MuiTablePagination-root': {
          ...styles.root,
          border: 'none', // Elimina el borde del contenedor principal
        },
        '& .MuiTablePagination-toolbar': {
          ...styles.root,
          border: 'none', // Elimina el borde de la barra de herramientas
        },
        '& .MuiTablePagination-actions': {
          border: 'none', // Asegúrate de que no haya bordes en las acciones
        },
        '& .MuiTablePagination-input': styles.label,
        '& .MuiTablePagination-select': styles.label,
      }}
    />
  )
}
