import React from 'react'
import { Messenger as M } from '../../features/messenger'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { RouteProps } from '../../routes/AppRouter'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import logoCareme from '../../assets/caremeLogoMain.svg'
import style from '../layout/Main.module.css'
import { Box } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'

type MessengerProps = {
  id?: string
  conversationID?: string
} & RouteProps

export function Messenger(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const { id } = useParams()
  const location = useLocation()

  setTitle(title)

  const state: MessengerProps = location?.state as MessengerProps

  return (
    <>
      <M id={id} conversationID={state?.conversationID} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}
