import React, { useEffect, useState } from 'react'
import { ViewProps } from './types'
import utc from 'dayjs/plugin/utc' // Importa el plugin utc
import style from './diaryView.module.css'
import dayjs from 'dayjs'
import { getMonday } from '../../common/utils/date'
import { Event as E } from '../../modules/calendar/models/Event'
import { Query, QueryParam } from '../../common/api/Query'
import { Box, Modal, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { getUserContainer } from '../../container/user-module'
import { reduceString } from '../../common/utils/strings'
import { CalendarModal } from './CalendarModal'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY, LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { getCalendarContainer } from '../../container/calendar-module'
import { EventService } from '../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../modules/calendar'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_CALENDAR_EXERCISES_ID,
  ROUTE_CALENDAR_FORM,
  ROUTE_CREATE,
} from '../../routes/routes-constants'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { EventCategory } from '../../modules/calendar/enums/EventCategory'
import { Tooltip } from '@mui/material'
import reviewIcon from '../../assets/events_icons/bola-revision.svg'
import dateIcon from '../../assets/calendar/cita.svg'
import vaccineIcon from '../../assets/events_icons/bola-vacunas.svg'
import adviceIcon from '../../assets/events_icons/bola-consejos.svg'
import landmarkIcon from '../../assets/events_icons/bola-hitos.svg'
import trainingIcon from '../../assets/calendar/ejercicio.svg'
import formIcon from '../../assets/events_icons/evento-formulario.svg'
import othersIcon from '../../assets/events_icons/bola-otros.svg'
import medicationIcon from '../../assets/dashboard/treatmentIconEvent.svg'
import { useLocation, useNavigate } from 'react-router-dom'

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)
dayjs.extend(utc)

const hours = (): string[] => {
  const hourRange = []
  for (let i = 7; i < 23; i++) {
    if (i < 10) {
      hourRange.push('0' + i + ':00')
    } else {
      hourRange.push(i + ':00')
    }
  }
  return hourRange
}

type WeekViewProps = { id: string | undefined } & ViewProps

type WeekEvent = {
  event: E
}
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
export function DiaryView(props: WeekViewProps) {
  const { t } = useTranslation()
  const [selectedEvent, setSelectedEvent] = useState<E>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [events, setEvents] = useState<E[]>([])
  const [monday, setMonday] = useState<Date>(getMonday(props.selectedDate))
  const [date, setDate] = useState<Date>(props.selectedDate || new Date())
  const loggedUser = loggedUserService.get()
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const finishDate = new Date(props.selectedDate)
    finishDate.setDate(finishDate.getDate() + 1)

    eventService
      .getFilteredList(
        new Query({
          query: [new QueryParam('startDate', props.selectedDate.toISOString())],
        }),
        props.id ? props.id : loggedUser?.id || ''
      )
      .subscribe((res) => {
        const routePattern = new RegExp(`^${ROUTE_CALENDAR_EXERCISES_ID.replace(':id', '[^/]+')}$`)
        console.log('if', routePattern.test(location.pathname))

        let filteredItems = res.items

        if (routePattern.test(location.pathname)) {
          filteredItems = filteredItems.filter((i) => i.eventCategory === EventCategory.Training)
        }

        const finalItems = filteredItems.filter((i) => {
          if (dayjs(i.startDate).format('HH').toString() === '01') {
            // Crear nuevas instancias de Date con las horas modificadas
            const modifiedStartDate = new Date(i.startDate)
            const modifiedFinishDate = new Date(i.finishDate)
            modifiedStartDate.setHours(7, 0, 0, 0)
            modifiedFinishDate.setHours(7, 0, 0, 0)
            // Asignar las nuevas instancias modificadas
            i.startDate = modifiedStartDate
            i.finishDate = modifiedFinishDate
          }

          return (
            new Date(i.startDate).getDate() === props.selectedDate.getDate() &&
            new Date(i.startDate).getMonth() === props.selectedDate.getMonth()
          )
        })

        setEvents(finalItems)
      })
  }, [props.selectedDate, isLoading])

  useEffect(() => {
    const tmpMonday = getMonday(props.selectedDate)
    tmpMonday.getTime() !== monday.getTime() && setMonday(tmpMonday)
  }, [props.selectedDate])

  const handleCloseModal = () => setOpenModal(false)

  const editEvent = (e: E) => {
    navigate(`${ROUTE_CALENDAR_FORM}/${e.id}`, { state: { date: date } })
    setOpenModal(false)
  }

  const deleteEvent = (e: E) => {
    if (e.id) {
      eventService.delete(e.id).subscribe(() => {
        setDate(new Date(date))
        setIsLoading(!isLoading)
      })
    }
    setOpenModal(false)
  }

  const getIcon = (type: EventCategory) => {
    switch (type) {
      case EventCategory.Reviews:
        return (
          <Tooltip title={t('reviews') || 'reviews'}>
            <img src={reviewIcon} alt={'reviews'} />
          </Tooltip>
        )

      case EventCategory.Medication:
        return (
          <Tooltip title={t('treatment') || 'treatment'}>
            <img src={medicationIcon} alt={'treatment'} />
          </Tooltip>
        )

      case EventCategory.Vaccines:
        return (
          <Tooltip title={t('vaccine') || 'vaccine'}>
            <img src={vaccineIcon} alt={'vaccine'} />
          </Tooltip>
        )

      case EventCategory.Appointment:
        return (
          <Tooltip title={t('medicalappointment') || 'medicalappointment'}>
            <img src={dateIcon} alt={'medicalappointment'} />
          </Tooltip>
        )

      case EventCategory.Advices:
        return (
          <Tooltip title={t('advices') || 'advices'}>
            <img src={adviceIcon} alt={'advices'} />
          </Tooltip>
        )

      case EventCategory.Landmarks:
        return (
          <Tooltip title={t('landmarks') || 'landmarks'}>
            <img src={landmarkIcon} alt={'landmarks'} />
          </Tooltip>
        )

      case EventCategory.Training:
        return (
          <Tooltip title={t('training') || 'training'}>
            <img src={trainingIcon} alt={'training'} />
          </Tooltip>
        )
      case EventCategory.Forms:
        return (
          <Tooltip title={t('form') || 'form'}>
            <img src={formIcon} alt={'training'} />
          </Tooltip>
        )

      default:
        return (
          <Tooltip title={t('others') || 'others'}>
            <img src={othersIcon} alt={'others'} />
          </Tooltip>
        )
    }
  }

  const getColorByCategory = (category: number) => {
    let color

    switch (category) {
      case 1:
        color = '#DBE0A8'
        break
      case 2:
        color = '#ADB84E'
        break
      case 3:
        color = '#d2617e'
        break
      case 4:
        color = '#eadf33'
        break
      case 5:
        color = '#3fbfcc'
        break
      case 6:
        color = '#DBE0A8'
        break
      case 7:
        color = '#815abb'
        break
      case 8:
        color = '#9b8c58'
        break
      default:
        color = '#959595'
        break
    }
    return color
  }

  const handlerClick = (event: E) => {
    setSelectedEvent(event)
    setOpenModal(true)
  }

  function DiaryEvent2(props: WeekEvent): JSX.Element {
    let duration
    if (
      dayjs(props.event.finishDate).format('DD').toString() ===
      dayjs(props.event.startDate).format('DD').toString()
    ) {
      duration =
        Number(dayjs(props.event.finishDate).format('HH')) -
        Number(dayjs(props.event.startDate).format('HH'))
    }

    return (
      <>
        {props.event.startDate.getHours() === props.event.finishDate.getHours() ? (
          <Box
            className={style.eventNotSameDay}
            onClick={() => handlerClick(props.event)}
            style={{
              backgroundColor: getColorByCategory(props.event.eventCategory),
            }}
          >
            <div className={style.iconEventCategoryNotSameDay}>
              {getIcon(props.event.eventCategory)}
            </div>
            <h3 className={style.eventTitleNotSameDay}>
              {dayjs(props.event.startDate).format('HH:mm') +
                ' ' +
                reduceString(props.event.title, 15)}
            </h3>
          </Box>
        ) : (
          <Box
            className={style.event}
            onClick={() => handlerClick(props.event)}
            style={{
              ['--duration-height' as any]: duration,
              ['--col' as any]: getColorByCategory(props.event.eventCategory),
              backgroundColor: getColorByCategory(props.event.eventCategory),
            }}
          >
            <div className={style.iconEventCategory}>{getIcon(props.event.eventCategory)}</div>
            <h3 className={style.eventTitle}>
              {dayjs(props.event.startDate).format('HH:mm') +
                ' ' +
                reduceString(props.event.title, 15)}
            </h3>
          </Box>
        )}
      </>
    )
  }

  const createEvent = () =>
    navigate(`${ROUTE_CALENDAR_FORM}/${ROUTE_CREATE}`, { state: { date: date } })

  return (
    <>
      <Table
        sx={{
          borderRadius: '27px',
          background: 'transparent',
          width: '100%',
          border: '1px dotted #ADB84E',
          '& td': {
            borderStyle: 'dotted',
            padding: '0 5px',
          },
          '& tr': {
            borderStyle: 'dotted',
            borderColor: '#ADB84E',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: openMenuLeft ? '' : '100%',
          }}
        >
          <TableBody>
            {hours().map((h, i) => (
              <TableRow key={h.toString()}>
                <TableCell key={i} className={style.hourRow}>
                  <div style={{ maxHeight: '42px' }}>
                    <div key={i}>{h}</div>
                  </div>
                </TableCell>
                <TableCell className={style.eventRow} key={String(h) + i}>
                  <div className={style.containerEvent}>
                    {events.map((event: E) =>
                      dayjs(event.startDate).format('DD').toString() ===
                        dayjs(event.finishDate).format('DD').toString() &&
                      h.split(':')[0] === dayjs(event.startDate).format('HH').toString() ? (
                        <DiaryEvent2 event={event} key={event.id} />
                      ) : (
                        dayjs(event.startDate).format('DD').toString() !==
                          dayjs(event.finishDate).format('DD').toString() &&
                        h.split(':')[0] === dayjs(event.startDate).format('HH').toString() && (
                          <Box
                            className={style.eventNotSameDay}
                            onClick={() => handlerClick(event)}
                            style={{
                              backgroundColor: getColorByCategory(event.eventCategory),
                            }}
                          >
                            <div className={style.iconEventCategoryNotSameDay}>
                              {getIcon(event.eventCategory)}
                            </div>
                            <h3 className={style.eventTitleNotSameDay}>
                              {dayjs(event.startDate).format('HH:mm') +
                                ' ' +
                                reduceString(event.title, 15)}
                            </h3>
                          </Box>
                        )
                      )
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </div>
      </Table>
      {selectedEvent && (
        <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
          <CalendarModal
            event={selectedEvent}
            handleClose={handleCloseModal}
            handleEdit={editEvent}
            handleRemove={deleteEvent}
          />
        </Modal>
      )}
    </>
  )
}
