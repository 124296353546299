import React, { FormEvent, useEffect, useState } from 'react'
import { emptyFormDTO, FormDTO, FormFieldDTO, fromModel } from '../../modules/forms/models/FormDTO'
import { getFormContainer } from '../../container/form-module'
import { FormService } from '../../modules/forms/services/FormService'
import { FORM_SERVICE_KEY } from '../../modules/forms'
import { FormCard } from '../../components/form-card/FormCard'
import { Box } from '@mui/material'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { EditorFields } from './EditorFields'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_FORM_GENERATOR } from '../../routes/routes-constants'
import { isSpecialType } from '../../common/utils/enums'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import style from './Editor.module.css'
import { Header } from '../../components/header/Header'
import { CronItemMockup } from 'components/form-card/CronItemMockup'
import { Cron, cronString } from '../../modules/forms/enums/Cron'
import { PreviewForm } from './PreviewForm'
import { useNavigate } from 'react-router-dom'

enum FormFieldsError {
  NO_FIELDS = 'formShouldHaveAtLeastAField',
  ORDER = 'formFieldsErrorOrder',
  NO_OPTIONS = 'formFieldsErrorNoOptions',
}

type EditorProps = {
  id?: string
}

const formContainer = getFormContainer()
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()
  const cronTypes = cronString()
  const navigate = useNavigate()

  const [form, setForm] = useState<FormDTO>(emptyFormDTO())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isRecurrence, setIsRecurrence] = useState<boolean>(false)
  const [cron, setCron] = useState<string>(cronTypes[Cron.Empty])
  const [preview, setPreview] = useState<boolean>(false)
  const [dataPreview, setDataPreview] = useState<FormDTO>(emptyFormDTO())

  const handleChangeRecurrence = (s: string) => setCron(s)

  useEffect(() => {
    if (!props?.id) {
      return
    }
    formService.getByID(props.id).subscribe((res) => {
      if (res) {
        setForm(fromModel(res))
        console.log(JSON.stringify(form))
      }
    })
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setForm(Object.assign({ ...form }, { [e.target.name]: e.target.value }))

  const changeFormFields = (formFields: FormFieldDTO[]) => (form.formFields = formFields)

  const saveForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }

    const newFormFields: FormFieldDTO[] = []
    form.formFields.forEach((ff) => {
      newFormFields.push(Object.assign({ ...ff }, { formID: form.id, order: ff.order }))
    })
    const newForm = Object.assign({ ...form }, { formFields: newFormFields, repeating: cron })
    if (props.id) {
      formService.update(newForm).subscribe(() => goBack())
      return
    }
    formService.add(newForm).subscribe(() => goBack())
  }

  const validateForm = () => {
    if (
      form.formFields.some((f) =>
        form.formFields.find((ff) => ff.id !== f.id && ff.order + '' === f.order + '')
      )
    ) {
      setErrorMessage(FormFieldsError.ORDER)
      return false
    }

    if (
      form.formFields.some(
        (f) => isSpecialType(f.type) && (!f.optionValues || f.optionValues?.length === 0)
      )
    ) {
      setErrorMessage(FormFieldsError.NO_OPTIONS)
      return false
    }

    if (form.formFields.length === 0) {
      setErrorMessage(FormFieldsError.NO_FIELDS)
      return false
    }

    setErrorMessage('')
    return true
  }

  const goBack = () => navigate(ROUTE_FORM_GENERATOR)

  const showPreview = () => {
    const newFormFields: FormFieldDTO[] = []
    form.formFields.forEach((ff) => {
      newFormFields.push(Object.assign({ ...ff }, { formID: form.id, order: ff.order }))
    })
    const newForm = Object.assign({ ...form }, { formFields: newFormFields, repeating: cron })
    setDataPreview(newForm)
    setPreview(true)
  }

  return (
    <Box className={genericStyle.pageContainer}>
      {preview ? (
        <>
          <PreviewForm
            title={dataPreview.title}
            description={dataPreview.description}
            formFields={dataPreview.formFields}
            repeating={dataPreview.repeating}
            id=""
          />

          <Box display="flex" justifyContent="flex-end">
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type="button"
              label={t('closePreview')}
              handler={() => {
                setPreview(false)
              }}
            />
          </Box>
        </>
      ) : (
        <FormCard>
          <>
            <Box style={{ marginBottom: '40px' }}>
              <Header label={t('newForm')} />
            </Box>
            <form onSubmit={(e) => saveForm(e)}>
              <Box mb={3} className={style.box} display="flex">
                <b className={style.titleLabel}>{t('title')}*</b>
                <Box display="flex" className={style.textField}>
                  <TextFieldItem
                    field="title"
                    value={form.title}
                    label={''}
                    type={'text'}
                    handleChange={handleInput}
                    rows={undefined}
                    required={true}
                  />
                </Box>
              </Box>

              <Box mb={3} className={style.box} display="flex">
                <b className={style.descriptionLabel}>{t('description')}*</b>
                <Box display="flex" className={style.textField}>
                  <TextFieldItem
                    field="description"
                    value={form.description}
                    label={''}
                    type={'text'}
                    handleChange={handleInput}
                    rows={4}
                    required={true}
                  />
                </Box>
              </Box>
              {/*  <Box mb={3} className={style.box} display="flex">
                <b className={style.descriptionLabel} style={{ marginTop: '1.2%' }}>
                  {t('recurrence')}
                </b>
                <Box display="flex" className={style.checkBox}>
                  <Checkbox
                    checked={isRecurrence}
                    name={'recurrence'}
                    onChange={() => setIsRecurrence(!isRecurrence)}
                  />
                </Box>
              </Box>*/}
              {isRecurrence && (
                <Box mb={3}>
                  <CronItemMockup handleChange={handleChangeRecurrence} />
                </Box>
              )}
              <Box mb={3} className={style.box}>
                <EditorFields
                  formFields={form.formFields.sort((a, b) => a.order - b.order)}
                  handlerChange={changeFormFields}
                />
              </Box>
              {errorMessage && (
                <Box mb={3}>
                  <Alert severity="warning" key="errorMessage" id="errorMessage">
                    {t(errorMessage)}
                  </Alert>
                </Box>
              )}
              <Box display="flex" justifyContent="flex-end">
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('previewForm')}
                  handler={() => {
                    showPreview()
                  }}
                />
                <div className={style.separator} />
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={goBack}
                />
                <div className={style.separator} />
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('save')}
                  handler={() => {}}
                />
              </Box>
            </form>
          </>
        </FormCard>
      )}
    </Box>
  )
}
