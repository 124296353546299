import React, { useEffect, useState } from 'react'
import { Event } from '../../modules/calendar/models/Event'
import { Badge, Box, Button, Fade, Modal } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { ListView } from './ListView'
import { WeekView } from './WeekView'
import { MonthView } from './MonthView'
import { YearView } from './YearView'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import newEvent from '../../assets/calendar/add.svg'
import styles from './Calendar.module.css'
import { DayView } from './DayView'
import { ViewProps } from './types'
import { ROUTE_CALENDAR_FORM, ROUTE_CREATE } from '../../routes/routes-constants'
import { EventService } from '../../modules/calendar/services/EventService'
import { getCalendarContainer } from '../../container/calendar-module'
import { EVENT_SERVICE_KEY } from '../../modules/calendar'
import { Query, QueryParam } from '../../common/api/Query'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import CustomToolbar from './CustomToolbar'
import dayjs, { Dayjs } from 'dayjs'
import { Header } from '../../components/header/Header'
import style from './ListView.module.css'
import close from '../../assets/table_icons/ico-eliminar.svg'
import { getExerciseAlgorithmContainer } from '../../container/exerciseAlgorithm-module'
import { ExerciseAlgorithmService } from '../../modules/exerciseAlgorithm/services/ExerciseAlgorithm'
import { EXERCISEALGORITHM_SERVICE_KEY } from '../../modules/exerciseAlgorithm'
import { Alert } from '@mui/material'
import genericStyle from '../../common/utils/generic.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { DiaryView } from './DiaryView'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as DatePickerExercises } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'
import { WeekViewHome } from '../newsWall/WeekViewHome'
import { useNavigate } from 'react-router-dom'
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers'
import { EventCategory } from '../../modules/calendar/enums/EventCategory'

const calendarContainer = getCalendarContainer()
const exerciseAlgorithmContainer = getExerciseAlgorithmContainer()
const exerciseAlgorithmService = exerciseAlgorithmContainer.get<ExerciseAlgorithmService>(
  EXERCISEALGORITHM_SERVICE_KEY
)
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)

enum View {
  Day = 1,
  Week,
  Month,
  Year,
  List,
}

type CalendarProps = {
  id?: string
  selectedDate?: Date
}

export function CalendarExercises(props: CalendarProps) {
  const { t } = useTranslation()
  const [view, setView] = useState<View>(View.List)
  const [date, setDate] = useState<Date>(props.selectedDate || new Date())
  const [totalEvents, setTotalEvents] = useState<Event[]>([])
  const [tabValue, setTabValue] = useState<number>(0)
  const [tabsView, setTabsView] = useState<JSX.Element>(<></>)
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [badDates, setBadDates] = useState<string>('')
  const [GenerateExerciseUntil, setGenerateExerciseUntil] = useState<Date>(new Date())
  const [GenerateExerciseInit, setGenerateExerciseInit] = useState<Date>(new Date())
  const [openModalGenerate, setOpenModalGenerate] = useState<boolean>(false)
  const [message, setMessage] = useState<boolean>(true)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [isDatePickerStartOpen, setIsDatePickerStartOpen] = useState<boolean>(false)
  const [isDatePickerFinishOpen, setIsDatePickerFinishOpen] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const navigate = useNavigate()
  const innerWidth = window.innerWidth

  const customThemeCalendar = (theme: any) =>
    createTheme({
      ...theme,
      palette: {
        background: {
          default: 'transparent',
          paper: 'transparent',
        },
        primary: {
          main: '#ADB84E',
        },
        secondary: {
          main: '#ADB84E',
          contrastText: '#f9f9f9',
        },
      },
      components: {
        MuiBadge: {
          styleOverrides: {
            anchorOriginBottomRightCircular: {
              transform: 'translateX(-10px) translateY(2px)',
            },
          },
        },
        MuiPickersCalendarHeader: {
          styleOverrides: {
            label: {
              color: '#474747',
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '39px',
              letterSpacing: '-0.004em',
              textAlign: 'left',
              textTransform: 'capitalize',
            },
          },
        },
        MuiPickersToolbar: {
          styleOverrides: {
            root: {
              '& span': {
                display: 'none',
              },
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              display: 'none',
            },
          },
        },
      },
    })

  useEffect(() => {
    //iniciamos el la ficha de generacion de ejercicios al siguiente lunes
    var d = new Date()
    d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7 || 7))
    console.log(d)
    setGenerateExerciseInit(d)
    //fecha fin del generate exercise debe ser el proximo domingo
    d = new Date()
    d.setDate(d.getDate() + 7 + ((0 + 7 - d.getDay()) % 7 || 7))
    setGenerateExerciseUntil(d)
  }, [])

  useEffect(() => {
    setTabsView(
      getView(view, {
        selectedDate: date,
        handlerEdit: editEvent,
        handlerRemove: removeEvent,
        events: totalEvents,
      })
    )
  }, [view, date])

  const changeMonth = async (event: any) => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        if (event) {
          setCurrentDate(event.toDate())
        }
        resolve()
      }, 500)
    })
  }

  const getView = (view: View, vp: ViewProps): JSX.Element => {
    switch (view) {
      case View.Day:
        return <DayView {...vp} />
      case View.Week:
        return <WeekView id={props.id} {...vp} />
      case View.Month:
        return <MonthView {...vp} />
      case View.Year:
        return <YearView {...vp} />
      case View.List:
        return <ListView id={props.id} {...vp} />
    }
  }

  const editEvent = (event: Event) =>
    navigate(`${ROUTE_CALENDAR_FORM}/${event.id}`, { state: { date: date } })

  const removeEvent = (event: Event) => {
    if (event.id) {
      eventService.delete(event.id).subscribe(() => {
        setDate(new Date(date))
      })
    }
  }

  useEffect(() => {
    const startDate = new Date()
    startDate.setMonth(currentDate.getMonth())
    startDate.setDate(1)
    startDate.setFullYear(currentDate.getFullYear())
    const finishDate = new Date()
    finishDate.setMonth(currentDate.getMonth() + 1)
    finishDate.setDate(1)
    finishDate.setFullYear(currentDate.getFullYear())
    console.log(props.id)
    eventService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<Event>('startDate', startDate.toISOString()),
            new QueryParam<Event>('finishDate', finishDate.toISOString()),
          ],
        }),
        props.id || ''
      )
      .subscribe((res) => {
        const eventsOfExercises = res.items.filter(
          (e) => e.eventCategory === EventCategory.Training
        )
        setTotalEvents([...eventsOfExercises])
      })
  }, [currentDate])

  const createEvent = () =>
    navigate(`${ROUTE_CALENDAR_FORM}/${ROUTE_CREATE}`, { state: { date: date } })

  const handleTabChange = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    setTabValue(tabNumber)
    switch (tabNumber) {
      case 0:
        setView(View.List)
        break
      case 1:
        setView(View.Week)
        break
    }
  }

  const changeDate = (event: any) => setDate(event?.toDate() || new Date())

  const handleCloseModalGenerate = () => setOpenModalGenerate(false)

  const handleGenerateExercise = () => {
    setOpenModalGenerate(true)
  }

  const handleGenerateExercisesBackend = () => {
    if (GenerateExerciseUntil < GenerateExerciseInit) {
      setBadDates('La fecha de fin no puede ser anterior a la fecha inicio.')
    } else {
      exerciseAlgorithmService.generateExercises(
        window.location.href.split('/')[4],
        new Date(GenerateExerciseInit),
        GenerateExerciseUntil
      )

      setMessage(false)
      handleCloseModalGenerate()
      setTimeout(() => {
        setMessage(true)
      }, 5000)
    }
  }

  const renderDay = (props: PickersDayProps<Dayjs>) => {
    const { day, outsideCurrentMonth, ...other } = props
    // Ensure that the day prop is a Dayjs object
    const dayAsDayjs = dayjs(day) // Convert Date to Dayjs if necessary

    const dayFormatted = dayAsDayjs.format('YYYY,MM,DD')
    const isSelected =
      !outsideCurrentMonth &&
      totalEvents.map((e) => dayjs(e.startDate).format('YYYY,MM,DD')).includes(dayFormatted)

    return (
      <Badge
        style={{ backgroundColor: '#f9f9f9' }}
        color="secondary"
        badgeContent={isSelected ? '' : undefined}
        variant={isSelected ? 'dot' : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        overlap="circular"
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={dayAsDayjs} // Use the Dayjs object here
          selected={isSelected}
        />
      </Badge>
    )
  }

  const CalendarIcon = () => <img src={calendarIcon} alt="Calendar" />

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginLeft: innerWidth > 900 ? (openMenuLeft ? '50px' : '') : '',
        }}
      >
        <Box
          style={{
            maxWidth:
              innerWidth > 900
                ? openMenuLeft
                  ? 'calc(100% - 250px)'
                  : 'calc(100% - 300px)'
                : '95%',
            position: 'relative',
          }}
          className={genericStyle.pageContainer}
        >
          <Box className={genericStyle.container}>
            <Fade in={message} timeout={6000}>
              <Alert
                className={styles.message}
                hidden={message}
                severity="success"
                key="message"
                id="message"
              >
                {t('exercisesGenerated')}
              </Alert>
            </Fade>

            <Box className={styles.calendarContainer} display="flex" key={'box1'}>
              <DiaryView
                id={props.id}
                selectedDate={date}
                events={totalEvents}
                handlerEdit={() => {}}
                handlerRemove={() => {}}
              />
              <Box
                className={styles.calendarContainerLeft}
                display="flex"
                flexDirection="column"
                style={{ backgroundColor: '#f9f9f9' }}
                key={'box2'}
              >
                <CustomToolbar date={date} />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <ThemeProvider theme={customThemeCalendar}>
                    <StaticDatePicker
                      className={styles.calendar}
                      orientation="portrait"
                      value={dayjs(date)}
                      onChange={changeDate}
                      onMonthChange={changeMonth}
                      slots={{ day: renderDay }}
                      slotProps={{ toolbar: { hidden: true } }}
                    />
                  </ThemeProvider>
                </LocalizationProvider>
                {!props.id && (
                  <Box className={styles.newEventButtonContainer}>
                    <Button className={styles.newEventButton} type={'button'} onClick={createEvent}>
                      <span className={styles.newEventText}>{t('newEvent')}</span>
                      <img src={newEvent} alt="newEvent" width={27} />
                    </Button>
                  </Box>
                )}

                {props.id && (
                  <Box className={styles.newEventButtonContainer}>
                    <Button
                      className={styles.newEventButton}
                      type={'button'}
                      onClick={handleGenerateExercise}
                    >
                      <span className={styles.newEventTextExercises}>{t('generateExercises')}</span>
                      <img src={newEvent} alt="newEvent" width={27} />
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>

            <Modal
              open={openModalGenerate}
              className={style.eventModal}
              onClose={handleCloseModalGenerate}
            >
              <Box
                width="30%"
                padding={5}
                bgcolor="#f9f9f9"
                justifyContent={'space-between'}
                className={style.modalGenerateExercises}
              >
                <Header
                  label={t('generateExercises')}
                  icon={close}
                  onClick={handleCloseModalGenerate}
                />
                <Box
                  mb={3}
                  mt={7}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                  className={style.datePickerContainer}
                >
                  <Box
                    mb={4}
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    className={style.datePicker}
                  >
                    <p
                      style={{ fontFamily: 'Montserrat-SemiBold' }}
                      className={style.dateLabel + ' ' + { width: '50%' }}
                    >
                      {t('startDate')}
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                      <DatePickerExercises
                        open={isDatePickerStartOpen}
                        onOpen={() => setIsDatePickerStartOpen(true)}
                        onClose={() => setIsDatePickerStartOpen(false)}
                        key={'startDate'}
                        format="DD/MM/YYYY"
                        onChange={(e) => {
                          const init = new Date(e as unknown as Date)
                          init.setHours(8, 0, 0, 0)
                          setGenerateExerciseInit(init)
                        }}
                        value={dayjs(GenerateExerciseInit)}
                        label={''}
                        onError={(reason) => {
                          switch (reason) {
                            case 'invalidDate':
                              setDateTimePickerError(t('invalidDateMessage'))
                              break
                            case 'minDate':
                              setDateTimePickerError(t('minDateMessage'))
                              break
                          }
                        }}
                        slotProps={{
                          textField: {
                            id: 'startDate',
                            size: 'small',
                            InputProps: {
                              sx: {
                                '& fieldset': {
                                  borderRadius: 32,
                                  border: '1px solid #E8E7EC',
                                  fontFamily: 'Montserrat',
                                },
                                '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                                '& .MuiOutlinedInput-root': {
                                  border: 'none',
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#ADB84E',
                                  },
                                },
                              },
                              onClick: () => setIsDatePickerStartOpen(true),
                            },
                          },
                        }}
                        slots={{
                          openPickerIcon: CalendarIcon,
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    mr={4}
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    bgcolor={'#f9f9f9'}
                    className={style.datePicker2}
                  >
                    <p
                      style={{ fontFamily: 'Montserrat-SemiBold' }}
                      className={style.dateLabel + ' ' + { width: '50%' }}
                    >
                      {t('finishDate')}
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                      <DatePickerExercises
                        open={isDatePickerFinishOpen}
                        onOpen={() => setIsDatePickerFinishOpen(true)}
                        onClose={() => setIsDatePickerFinishOpen(false)}
                        key={'finishDate'}
                        format="DD/MM/YYYY"
                        minDate={dayjs().add(6, 'day')} // Directly use Dayjs object
                        onChange={(e) => {
                          const until = dayjs(e).hour(8).minute(0).second(0).toDate() // Convert Dayjs to Date
                          setGenerateExerciseUntil(until) // Now passing a Date object
                        }}
                        value={dayjs(GenerateExerciseUntil)} // Ensure this is a Dayjs object
                        label={''}
                        onError={(reason) => {
                          switch (reason) {
                            case 'invalidDate':
                              setDateTimePickerError(t('invalidDateMessage'))
                              break
                            case 'minDate':
                              setDateTimePickerError(t('minDateMessage'))
                              break
                          }
                        }}
                        slotProps={{
                          textField: {
                            id: 'finishDate',
                            size: 'small',
                            InputProps: {
                              sx: {
                                '& fieldset': {
                                  borderRadius: 32,
                                  border: '1px solid #E8E7EC',
                                  fontFamily: 'Montserrat',
                                },
                                '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                                '& .MuiOutlinedInput-root': {
                                  border: 'none',
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#ADB84E',
                                  },
                                },
                              },
                              onClick: () => setIsDatePickerFinishOpen(true),
                            },
                          },
                        }}
                        slots={{
                          openPickerIcon: CalendarIcon,
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <p style={{ color: 'red' }}>{badDates}</p>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  width={'100%'}
                  style={{ marginTop: '8%' }}
                >
                  <Box mr={2}>
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      type={'submit'}
                      label={t('save')}
                      handler={handleGenerateExercisesBackend}
                    />
                  </Box>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={t('close')}
                    handler={handleCloseModalGenerate}
                  />
                </Box>
              </Box>
            </Modal>
          </Box>
        </Box>
      </div>
    </>
  )
}
