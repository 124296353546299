import { RouteProps } from '../../routes/AppRouter'
import { TrainingView as TV } from '../../features/training'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import logoCareme from '../../assets/caremeLogoMain.svg'
import style from '../layout/Main.module.css'
import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

export function TrainingView(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const { id } = useParams()

  setTitle(title)
  return (
    <>
      <TV id={id} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}
