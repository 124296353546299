import { RouteProps } from '../../routes/AppRouter'
import { TrainingsView as TSV } from '../../features/training'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { useParams } from 'react-router-dom'

export function TrainingsView(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const { id } = useParams()

  setTitle(title)
  return <TSV id={id} />
}
