import { Box, RadioGroup, Alert, Autocomplete } from '@mui/material'
import { RouteProps } from 'routes/AppRouter'
import genericStyle from '../../common/utils/generic.module.css'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { ROUTE_PATIENTS } from '../../routes/routes-constants'
import styles from './Editor.module.css'
import { RolesService } from '../../modules/users/services/RolesServices'
import { getUserContainer } from '../../container/user-module'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import { Role } from 'modules/users/models/Role'
import { Button, FormControl, FormControlLabel, FormLabel, Typography } from '@mui/material'
import Radio from '@mui/material/Radio'
import { emptyUserDTO, fromModel, User, UserDTO } from '../../modules/users/models/User'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { IPatologyService, PATOLOGY_SERVICE_KEY } from '../../modules/patologies'
import { Query } from '../../common/api/Query'
import { getPatologyContainer } from '../../container/patologies-module'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import { InputTextDescription } from '../resource/userProfileStylesMui'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
const roleContainer = getUserContainer()
const roleService = roleContainer.get<RolesService>(ROLES_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const patologyService = getPatologyContainer().get<IPatologyService>(PATOLOGY_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

enum Messages {
  SUCCESS = 'Paciente añadido',
}

export function Editor(props: RouteProps) {
  const { innerWidth } = window
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [roles, setRoles] = useState<Role[]>([])
  const [diagnosis, setDiagnosis] = useState<string>()
  const [_, setGender] = useState<string>()
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [patient, setPatient] = useState<UserDTO>(emptyUserDTO())
  const [message, setMessage] = useState<string>()
  const [userLogged, setUserLogged] = useState<User>()
  const [selectedPatologies, setSelectedPatologies] = useState<string[]>([])
  const [possiblePatologies, setPossiblePatologies] = useState<string[]>([])
  const [translatePatologies, setTranslatePatologies] = useState(new Map<string, string>())
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser?.id).subscribe((lu) => {
      setUserLogged(lu)
    })
  }, [])

  useEffect(() => {
    patologyService.getFilteredList(new Query({})).subscribe((res) => {
      let pts = []
      let trans = new Map<string, string>()
      if (res) {
        for (let i = 0; i < res.count; i++) {
          pts.push(t(res.items[i].name))
          trans.set(t(res.items[i].name), res.items[i].name)
        }
      }
      setTranslatePatologies(trans)
      setPossiblePatologies(pts)
    })

    if (!props.id) {
      return
    }
    userService.getByID(props.id).subscribe((res) => {
      res && setPatient(fromModel(res))
    })

    let rolesAux = new Array<Role>()
    roleService.getAll().subscribe((res) => {
      res.forEach((e) => {
        //Ya que ahora mismo solo se puede este tipo de rol, pero después se podrán otros
        if (
          e.name === 'externProfessional' ||
          e.name === 'family/Tutor' ||
          e.name === 'carer/Teacher'
        ) {
          rolesAux.push(e)
        }
      })
      setRoles(rolesAux)
    })
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPatient(Object.assign({ ...patient }, { [e.target.id]: e.target.value }))
  }
  const handleAutocompleteChange = (pat: string[]) => {
    let trans: string[] = []
    pat.forEach((element: string) => {
      trans.push(translatePatologies.get(element) || '')
    })
    setPatient(Object.assign({ ...patient }, { ['patologies']: trans }))
  }

  const handleGender = (e: ChangeEvent<{ value: string }>) => {
    setGender(e.target.value)
    e.target.value === 'male' ? (patient.gender = 1) : (patient.gender = 2)
  }

  const handleBack = () => navigate(ROUTE_PATIENTS)

  const validateForm = () => {
    let editCalentar = document.getElementById('editUser') as HTMLFormElement
    if (!editCalentar.checkValidity()) {
      editCalentar.reportValidity()
      return false
    }
    if (
      !patient.email ||
      !patient.diagnosis ||
      !patient.dob ||
      !patient.gender ||
      !patient.lastName ||
      !patient.firstName ||
      !patient.historyRecordNumber
    ) {
      return false
    }
    return true
  }

  function dateIsValid(date: Date) {
    return !Number.isNaN(new Date(date).getTime())
  }

  const handleDateChange = (date: Date) => {
    let currentDate = new Date(date)
    setSelectedDate(date)
    setPatient(Object.assign({ ...patient }, { ['dob']: currentDate }))
  }

  const handleSave = (e: any) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }

    let newPatient = Object.assign(
      { ...patient },
      {
        language: userLogged?.language || 0,
        assignedID: loggedUserService.get()?.id || '',
      }
    )

    if (props.id) {
      userService.update(newPatient).subscribe(() => {
        statusService.sendStatus({ variant: 'success' })
        handleBack()
      })
    } else {
      userService.add(newPatient, navigator.language).subscribe((res) => {
        if (res) {
          statusService.sendStatus({ variant: 'success' })
          handleBack()
        }
      })
    }
  }

  const style = () => {
    if (innerWidth > 598) {
      return {
        borderWidth: '1px',
        borderColor: 'lightgrey',
        borderStyle: 'solid',
        margin: '0px 0px 0px -320px',
        padding: '5px 10px 5px 10px',
        width: '290px',
        color: 'grey',
      }
    } else {
      return {
        fontSize: '14px',
        margin: '0px 0px 0px -240px',
        padding: '5px 10px 5px 10px',
        width: '290px',
        color: 'grey',
      }
    }
  }

  const CalendarIcon = () => <img src={calendarIcon} alt="Calendar" />

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <div
        style={{ maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)' }}
        className={genericStyle.pageContainer}
      >
        <div className={genericStyle.container}>
          <form className={styles.form} id={'editUser'} onSubmit={handleSave}>
            <Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box className={styles.box}>
                  <Typography className={styles.inputTitle}>{t('name')}</Typography>
                  <Input
                    sx={{ width: '100%' }}
                    value={patient.firstName}
                    fullWidth
                    id="firstName"
                    label={''}
                    variant="outlined"
                    required={true}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
                <Box className={styles.box}>
                  <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
                  <Input
                    sx={{ width: '100%' }}
                    value={patient.lastName}
                    id="lastName"
                    label={''}
                    variant="outlined"
                    required={true}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box className={styles.box}>
                  <Typography className={styles.inputTitle}>{t('email')}</Typography>
                  <Input
                    sx={{ width: '100%' }}
                    value={patient.email}
                    id="email"
                    label={''}
                    variant="outlined"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
                <Box className={styles.box}>
                  <Typography className={styles.inputTitle}>{t('patologies')}</Typography>
                  <Autocomplete
                    multiple
                    className={styles.autocomplete}
                    value={selectedPatologies}
                    options={possiblePatologies}
                    //getOptionLabel={(opt) => t(opt.value)}
                    onChange={(_, v) => {
                      v && setSelectedPatologies(v)
                      handleAutocompleteChange(v)
                    }}
                    renderInput={(params) => (
                      <Input
                        {...params}
                        id="patologies"
                        label={''}
                        variant={'outlined'}
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                    noOptionsText={t('noElementsAvailable')}
                  />
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box className={styles.box}>
                  <FormControl>
                    <FormLabel
                      className={styles.inputTitle}
                      id="demo-row-radio-buttons-group-label"
                    >
                      {t('gender')}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      id="gender"
                      onChange={(e) => handleGender(e)}
                    >
                      <FormControlLabel value="male" control={<Radio />} label={t('male')} />
                      <FormControlLabel value="female" control={<Radio />} label={t('female')} />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box className={styles.box}>
                  <Typography className={styles.inputTitle}>{t('historyNumber')}</Typography>
                  <Input
                    sx={{ width: '100%' }}
                    id="historyRecordNumber"
                    label={''}
                    value={patient.historyRecordNumber}
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setPatient(
                        Object.assign({ ...patient }, { [e.target.id]: Number(e.target.value) })
                      )
                    }
                  />
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box className={styles.box}>
                  <Typography className={styles.inputTitle}>{t('diagnosisSummary')}</Typography>
                  <Input
                    sx={{ width: '100%' }}
                    value={patient.diagnosisSummary}
                    id="diagnosisSummary"
                    name="diagnosisSummary"
                    label={''}
                    variant="outlined"
                    required={true}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
                <Box className={styles.box}>
                  <Typography className={styles.inputTitle}>{t('birthDate')}</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DatePicker
                      key={'dob'}
                      value={dayjs(patient.dob)}
                      open={isDatePickerOpen}
                      onOpen={() => setIsDatePickerOpen(true)}
                      onClose={() => setIsDatePickerOpen(false)}
                      format={'DD/MM/YYYY'}
                      onChange={(e) => {
                        handleDateChange(e as unknown as Date)
                      }}
                      label={''}
                      onError={(reason) => {
                        switch (reason) {
                          case 'invalidDate':
                            setDateTimePickerError(t('invalidDateMessage'))
                            break
                          case 'minDate':
                            setDateTimePickerError(t('minDateMessage'))
                            break
                        }
                      }}
                      slotProps={{
                        textField: {
                          id: 'dob',
                          size: 'small',
                          InputProps: {
                            sx: {
                              '& fieldset': {
                                borderRadius: 32,
                                border: '1px solid #E8E7EC',
                                fontFamily: 'Montserrat',
                              },
                              '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                '&.Mui-focused fieldset': {
                                  borderColor: '#ADB84E',
                                },
                              },
                            },
                            type: 'button',
                            onClick: () => setIsDatePickerOpen(true),
                          },
                        },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box className={styles.box}>
                  <Typography className={styles.inputTitle}>{t('diagnosis')}</Typography>
                  <InputTextDescription
                    sx={{ width: '100%' }}
                    multiline
                    rows={6}
                    value={patient.diagnosis}
                    id="diagnosis"
                    name="diagnosis"
                    label={''}
                    size={'medium'}
                    variant="outlined"
                    required={true}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  className={styles.box}
                >
                  <Box className={styles.buttons} mb={3} display="flex" justifyContent={'center'}>
                    <Box style={{ marginRight: 8 }}>
                      <Button className={styles.buttonCancel} type={'button'} onClick={handleBack}>
                        {t('back')}
                      </Button>
                    </Box>
                    <Button
                      className={styles.buttonAdd}
                      type={'submit'}
                      onClick={(e) => handleSave(e)}
                    >
                      {props.id ? t('edit_icon') : t('add')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
          {message && (
            <Box mb={3}>
              <Alert className={styles.message} severity="success" key="message" id="message">
                {t(message)}
              </Alert>
            </Box>
          )}
        </div>
      </div>
    </div>
  )
}
