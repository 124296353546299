import React, { useRef, useState } from 'react'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from './modules/users'
import { useTranslation } from 'react-i18next'
import background from './assets/login/background.png'
import styles from './Login.module.css'
import logoCareme from './assets/login/logo-careme-login.svg'
import {
  Box,
  Typography,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { AppButton, ButtonTheme } from './components/app-button/AppButton'
import { toModel } from 'modules/users/models/LoggedUserDTO'
import { LoggedUser } from 'modules/users/models/LoggedUser'
import questionIcon from 'assets/login/question.svg'
import { Input } from './loginStylesMui'
import { Header } from './components/header/Header'
import close from './assets/articles/delete.svg'
import { Input as InputDialog } from './features/resource/userProfileStylesMui'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export type LoginProps = {
  onLogin: (user: LoggedUser) => void
  handlePrivacyPolicy: () => void
  handleSetCookies: () => void
}

export default function Login(props: LoginProps) {
  const { t } = useTranslation()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [hasError, setHasError] = useState(false)
  const [modalPasswordOpened, setModalPasswordOpened] = useState<boolean>(false)
  const [recoverEmail, setRecoverEmail] = useState<string>('')
  const [showForm, setShowForm] = useState<boolean>(false)
  const formRef = useRef<HTMLDivElement>(null)
  const [showTerms, setShowTerms] = useState(false)
  const termsContainer = useRef<HTMLDivElement>(null)
  const [delayHandler, setDelayHandler] = useState<ReturnType<typeof setTimeout> | null>(null)

  const handlerLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doLogin()
  }

  const doLogin = () => {
    loggedUserService.login({ login, password }).subscribe((res) => {
      if (!res) {
        setHasError(true)
        return
      } else {
        props.onLogin(toModel(res))
      }
    })
  }

  const handleSendNewPassword = () => {
    loggedUserService.sendRecoverPassword(recoverEmail)
    setModalPasswordOpened(false)
  }

  const handleClose = () => {
    setModalPasswordOpened(false)
  }

  const handleClick = () => {
    setShowForm(true)
  }

  return (
    <div
      className={styles.background}
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(173, 184, 78, 0.43), rgba(173, 184, 78, 0.43)), url(${background})`,
      }}
    >
      <Container
        component="div"
        style={{ display: 'flex', flexDirection: 'column' }}
        className={styles.mainContainer}
      >
        <div className={styles.container}>
          {showForm ? (
            <form className={styles.formContainer} noValidate onSubmit={handlerLogin}>
              <>
                <img src={logoCareme} alt="Logo Careme" className={styles.logo} />
                <Box className={styles.inputContainer}>
                  <Input
                    placeholder={t('email')}
                    variant="outlined"
                    margin="normal"
                    required
                    id="login"
                    label=""
                    name="login"
                    autoComplete="login"
                    autoFocus
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                  />
                  <Input
                    placeholder={t('password')}
                    variant="outlined"
                    margin="normal"
                    required
                    name="password"
                    label=""
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Typography
                    className={styles.forgottenPasswordText}
                    onClick={() => setModalPasswordOpened(true)}
                  >
                    {t('forgottenPassword')}
                  </Typography>
                  <Box className={styles.button}>
                    <AppButton
                      theme={ButtonTheme.buttonLogin}
                      type={'submit'}
                      handler={doLogin}
                      label={t('enter')}
                      fullWidth={true}
                    />
                  </Box>
                </Box>
                {hasError && <Typography color="error">{t('auth error text')}</Typography>}
              </>
            </form>
          ) : (
            <div ref={formRef} className={styles.formContainerNoShowForm} onClick={handleClick}>
              <img src={logoCareme} alt="Logo Careme" className={styles.logoNoShowForm} />
            </div>
          )}
        </div>
        <Box className={styles.questions}>
          <div
            className={styles.termsContainer}
            style={{ display: showTerms ? 'block' : 'none' }}
            ref={termsContainer}
            onMouseEnter={() => {
              clearTimeout(delayHandler as ReturnType<typeof setTimeout>)
            }}
            onMouseLeave={() => {
              setDelayHandler(
                setTimeout(() => {
                  setShowTerms(false)
                }, 500)
              )
            }}
          >
            <Box className={styles.policyPrivacyContainer}>
              <Typography className={styles.policyPrivacyText} onClick={props.handlePrivacyPolicy}>
                {t('privacy')}
              </Typography>
              <Typography className={styles.policyPrivacyText} onClick={props.handleSetCookies}>
                {t('cookies')}
              </Typography>
            </Box>
          </div>
          <Box
            className={styles.questionContainer}
            onMouseEnter={() => {
              clearTimeout(delayHandler as ReturnType<typeof setTimeout>)
              setShowTerms(true)
            }}
            onMouseLeave={() => {
              // Inicia un retraso antes de ocultar el div
              setDelayHandler(
                setTimeout(() => {
                  setShowTerms(false)
                }, 500)
              ) // 500ms de retraso
            }}
          >
            <img src={questionIcon} alt="logo question" />
          </Box>
        </Box>
      </Container>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '32px',
            padding: '49px 50px',
            background: '#f9f9f9',
          },
        }}
        open={modalPasswordOpened}
        onClose={handleClose}
      >
        <Header label={t('forgotPasswordModalTitle')} icon={close} onClick={handleClose} />
        <DialogContent>
          <DialogContentText sx={{ fontFamily: 'Montserrat', padding: 0 }}>
            {t('forgotPasswordModalText')}
          </DialogContentText>
          <InputDialog
            variant="outlined"
            margin="normal"
            fullWidth
            name="emailRecover"
            placeholder={t('email')}
            label={''}
            id="emailRecover"
            value={recoverEmail}
            onChange={(e) => setRecoverEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            handler={handleSendNewPassword}
            label={t('accept')}
          />
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            handler={handleClose}
            label={t('cancel')}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}
