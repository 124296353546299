import style from './CurrentNavHeader.module.css'
import { getUserContainer } from '../../container/user-module'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'
import { useEffect, useState } from 'react'
import { User } from '../../modules/users/models/User'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export type CurrentNavHeaderProps = {
  title: string
  subtitle?: string
}

const circleService = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function CurrentNavHeader(props: CurrentNavHeaderProps): JSX.Element {
  const { t } = useTranslation()

  const [circle, setCircle] = useState<User | undefined>(circleService.getActive())

  useEffect(() => {
    circleService.getActiveObservable().subscribe((c) => setCircle(c))
  }, [circle])

  return (
    <div className={style.currentNavHeaderContainer}>
      <Box display="flex" justifyContent="space-between">
        <div>
          <h1 className={style.title}>{props.title.toUpperCase()}</h1>
          {props.subtitle && (
            <h2>{props.subtitle.slice(0, 1).toUpperCase() + props.subtitle.slice(1)}</h2>
          )}
        </div>
      </Box>
    </div>
  )
}
