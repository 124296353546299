import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getTrainingContainer } from '../../container/training-module'
import {
  ITRainingService,
  IUserTrainingService,
  TRAINING_SERVICE_KEY,
  USER_TRAINING_SERVICE_KEY,
} from '../../modules/training'
import genericStyle from '../../common/utils/generic.module.css'
import training1 from '../../assets/training/training1.e56745ed.png'
import training2 from '../../assets/training/training2.f64bb88e.png'
import training3 from '../../assets/training/training3.4a9558d5.png'
import styles from './viewStyle.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import {
  emptyUserTrainingDTO,
  fromModel,
  UserTrainingDTO,
} from '../../modules/training/models/UserTrainingDTO'
import { emptyTrainingDTO, TrainingDTO } from '../../modules/training/models/TrainingDTO'
import { ROUTE_DASHBOARD_TRAINING, ROUTE_TRAINING } from '../../routes/routes-constants'
import { User } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Typography } from '@mui/material'
import { getMediaContainer } from '../../container/media-module'
import { MediaService } from '../../modules/media/services/MediaService'
import { MEDIA_SERVICE_KEY } from '../../modules/media'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { useNavigate } from 'react-router-dom'

type TrainingView = {
  id?: string
}

const userContainer = getUserContainer()
const mediaService = getMediaContainer().get<MediaService>(MEDIA_SERVICE_KEY)
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const trainingService = getTrainingContainer().get<ITRainingService>(TRAINING_SERVICE_KEY)
const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)

export function View(props: TrainingView) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [user, setUser] = useState<User>()
  const [image, setImage] = useState<string>('')
  const [training, setTraining] = useState<TrainingDTO>(emptyTrainingDTO())
  const [userTraining, setUserTraining] = useState<UserTrainingDTO>(emptyUserTrainingDTO())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const innerWidth = window.innerWidth

  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      res && setUser(res)
    })
  }, [])

  useEffect(() => {
    if (!isLoading || !props?.id) {
      return
    }
    userTrainingService.getByID(props.id).subscribe((res) => {
      res && setUserTraining(fromModel(res))
    })
  }, [isLoading])

  useEffect(() => {
    if (!isLoading || !userTraining?.trainingID) {
      return
    }
    trainingService.getByID(userTraining?.trainingID).subscribe((res) => {
      res && setTraining(res)
      setIsLoading(!isLoading)
    })
  }, [userTraining])

  useEffect(() => {
    //recuperar imagen del entrenamiento del backend
    console.log(JSON.stringify(training))
    mediaService.getByName(training.title).subscribe((res) => {
      if (res) {
        setImage(res.data)
      }
    })
  }, [training])

  const selectImage = (image: string) => {
    switch (image) {
      case 'training1':
        return training1
      case 'training2':
        return training2
      case 'training3':
        return training3
    }
  }

  const handlerCompleted = () => {
    const newUserTraining = Object.assign({ ...userTraining }, { completed: true })

    if (!props.id) return
    userTrainingService.update(newUserTraining).subscribe((res) => setIsLoading(!isLoading))
  }

  const goBack = () => {
    navigate(
      user?.isPatient ? ROUTE_TRAINING : `${ROUTE_DASHBOARD_TRAINING}/${userTraining.userID}`
    )
  }

  return (
    <Box>
      {!isLoading && training ? (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: innerWidth > 900 ? (openMenuLeft ? '50px' : '') : '',
            }}
          >
            <Box
              style={{
                maxWidth:
                  innerWidth > 900
                    ? openMenuLeft
                      ? 'calc(100% - 250px)'
                      : 'calc(100% - 300px)'
                    : '95%',
              }}
              className={genericStyle.pageContainer}
            >
              <div className={genericStyle.headerContainer}>
                <Typography>{t(training.title)}</Typography>
              </div>
              <div className={genericStyle.container}>
                <Box className={styles.trainingContainer}>
                  <Box className={styles.description}>
                    <Box>
                      <p className={styles.descriptionTitle}>{`${t('description')}: `}</p>
                      <p className={styles.textDescription}>{t(training.description)}</p>
                    </Box>
                    <Box className={styles.contentData}>
                      <p className={styles.dateText}>
                        {userTraining && (
                          <>
                            <span className={styles.titleData}>{`${t('date')}: `}</span>
                            <span>{new Date(userTraining?.startDate).toLocaleDateString()}</span>
                          </>
                        )}
                      </p>
                      <p className={styles.dateText}>
                        {/*  {userTraining && (
                      <>
                        <span className={styles.titleData}>{`${t('finishDate')}: `}</span>
                        <span>{new Date(userTraining?.finishDate).toLocaleDateString()}</span>
                      </>
                    )}*/}
                      </p>
                      <p className={styles.dateText}>
                        <span className={styles.titleData}>{`${t('repetitions')}: `}</span>
                        <span>
                          {userTraining.minReps == userTraining.maxReps
                            ? userTraining.minReps
                            : userTraining.minReps + '-' + userTraining.maxReps}
                        </span>
                      </p>
                      <Box display={'flex'}>
                        <p className={styles.dateText}>
                          <span className={styles.titleData}>{`${t('state')}: `}</span>
                        </p>
                        <p
                          className={
                            userTraining?.completed
                              ? styles.styleCompleted
                              : styles.styledNotcompleted
                          }
                        >
                          {userTraining.completed ? t('completed') : t('notComplete')}
                        </p>
                      </Box>
                    </Box>
                    <Box className={styles.buttons}>
                      <Box style={{ margin: 8 }}>
                        <AppButton
                          label={t('back')}
                          theme={ButtonTheme.NewPrimary}
                          handler={goBack}
                          type={'button'}
                        />
                      </Box>
                      {userTraining.completed || !user?.isPatient ? (
                        ''
                      ) : (
                        <Box style={{ margin: 8 }}>
                          <AppButton
                            label={t('complete')}
                            theme={ButtonTheme.NewPrimary}
                            handler={handlerCompleted}
                            type={'button'}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <img className={styles.trainingImage} src={`data:image/png;base64,${image}`} />
                  </Box>
                </Box>
              </div>
            </Box>
          </div>
        </>
      ) : (
        ''
      )}
    </Box>
  )
}
