import * as React from 'react'
import { useEffect, useState } from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import homeIcon from '../../assets/new-left-menu/home.svg'
import iconPatient from '../../assets/new-left-menu/patients.svg'
import iconQuery from '../../assets/new-left-menu/consultas.svg'
import iconNews from '../../assets/new-left-menu/news.svg'
import iconResources from '../../assets/new-left-menu/resources.svg'
import iconCalendar from '../../assets/left_menu/icono-calendario.svg'
import iconStadistics from '../../assets/new-left-menu/statistics.svg'
import iconTraining from '../../assets/new-left-menu/training.svg'
import styles from '../header/Header.module.css'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { User } from '../../modules/users/models/User'
import { UserService } from '../../modules/users/services/UserService'

type ListItemsProps = {
  handleClick: (type: string) => void
  isOpen: boolean
  setOpen: (b: boolean) => void
}
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const ListItems = (props: ListItemsProps) => {
  const { t } = useTranslation()
  const [showRegister, setShowRegister] = useState<boolean>(false)
  const [user, setUser] = useState<User>()
  const pathname = window.location.pathname
  const loggedUser = loggedUserService.get()

  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser.id).subscribe((res) => {
      setUser(res)
    })
  }, [loggedUser])

  const changeStyleIcon = (path: string) => {
    const isPathMatched = path === pathname
    const isWindowWide = window.innerWidth > 900

    if (isPathMatched) {
      return isWindowWide ? styles.iconActive : styles.iconActiveResponsive
    } else {
      return isWindowWide ? styles.iconInactive : styles.iconInactiveResponsive
    }
  }

  const changeStyleText = (path: string) => {
    const isPathMatched = path === pathname
    const isWindowWide = window.innerWidth > 900

    if (isPathMatched) {
      return isWindowWide ? styles.textActive : styles.textActiveResponsive
    } else {
      return isWindowWide ? styles.textMenu : styles.textMenuResponsive
    }
  }

  const changeBackground = (path: string) => {
    return path === pathname ? styles.iconContainerActive : styles.iconContainer
  }

  return (
    <List
      className={window.innerWidth > 900 ? styles.listContainer : styles.listContainerResponsive}
    >
      <OverlayTrigger placement="right" overlay={<></>}>
        <ListItem
          //className={changeBackground('/newsWall')}
          button
          className={window.innerWidth < 900 ? styles.listItemResponsive : ''}
          onClick={() => {
            props.handleClick('START')
          }}
        >
          <ListItemIcon className={window.innerWidth < 900 ? styles.listItemIconResponsive : ''}>
            <img className={changeStyleIcon('/newsWall')} src={homeIcon} />
          </ListItemIcon>
          <ListItemText className={changeStyleText('/newsWall')} primary={t('start')} />
        </ListItem>
      </OverlayTrigger>
      {user && user.isPatient ? (
        ''
      ) : (
        <OverlayTrigger placement="right" overlay={<></>}>
          <ListItem
            //className={changeBackground('/patients')}
            button
            className={window.innerWidth < 900 ? styles.listItemResponsive : ''}
            onClick={() => props.handleClick('PATIENTS')}
          >
            <ListItemIcon className={window.innerWidth < 900 ? styles.listItemIconResponsive : ''}>
              <img className={changeStyleIcon('/patients')} src={iconPatient} />
            </ListItemIcon>
            <ListItemText className={changeStyleText('/patients')} primary={t('patients')} />
          </ListItem>
        </OverlayTrigger>
      )}
      <OverlayTrigger placement="right" overlay={<></>}>
        <ListItem
          //className={changeBackground('/messenger')}
          button
          className={window.innerWidth < 900 ? styles.listItemResponsive : ''}
          onClick={() => {
            props.handleClick('QUERIES')
          }}
        >
          <ListItemIcon className={window.innerWidth < 900 ? styles.listItemIconResponsive : ''}>
            <img className={changeStyleIcon('/messenger')} src={iconQuery} />
          </ListItemIcon>
          <ListItemText className={changeStyleText('/messenger')} primary={t('queries')} />
        </ListItem>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={<></>}>
        <ListItem
          //className={changeBackground('/articles')}
          button
          className={window.innerWidth < 900 ? styles.listItemResponsive : ''}
          onClick={() => props.handleClick('NEWS')}
        >
          <ListItemIcon className={window.innerWidth < 900 ? styles.listItemIconResponsive : ''}>
            <img className={changeStyleIcon('/articles')} src={iconNews} />
          </ListItemIcon>
          <ListItemText className={changeStyleText('/articles')} primary={t('news')} />
        </ListItem>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={<></>}>
        <ListItem
          //className={changeBackground('/resources')}
          button
          className={window.innerWidth < 900 ? styles.listItemResponsive : ''}
          onClick={() => props.handleClick('RESOURCES')}
        >
          <ListItemIcon className={window.innerWidth < 900 ? styles.listItemIconResponsive : ''}>
            <img className={changeStyleIcon('/resources')} src={iconResources} />
          </ListItemIcon>
          <ListItemText className={changeStyleText('/resources')} primary={t('resources2')} />
        </ListItem>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={<></>}>
        <ListItem
          //className={changeBackground('/calendar')}
          button
          className={window.innerWidth < 900 ? styles.listItemResponsive : ''}
          onClick={() => props.handleClick('SURVEYS')}
        >
          <ListItemIcon className={window.innerWidth < 900 ? styles.listItemIconResponsive : ''}>
            <img className={changeStyleIcon('/calendar')} src={iconCalendar} />
          </ListItemIcon>
          <ListItemText className={changeStyleText('/calendar')} primary={t('Calendario')} />
        </ListItem>
      </OverlayTrigger>

      {user && !user.isPatient ? (
        ''
      ) : (
        <OverlayTrigger placement="right" overlay={<></>}>
          <ListItem
            //className={changeBackground('/statistics')}
            button
            className={window.innerWidth < 900 ? styles.listItemResponsive : ''}
            onClick={() => props.handleClick('STATISTICS')}
          >
            <ListItemIcon className={window.innerWidth < 900 ? styles.listItemIconResponsive : ''}>
              <img className={changeStyleIcon('/statistics')} src={iconStadistics} />
            </ListItemIcon>
            <ListItemText className={changeStyleText('/statistics')} primary={t('statistics')} />
          </ListItem>
        </OverlayTrigger>
      )}

      {user && user.isPatient && (
        <OverlayTrigger placement="right" overlay={<></>}>
          <ListItem
            //className={changeBackground(user?.isPatient ? '/training' : '/trainings')}
            button
            className={window.innerWidth < 900 ? styles.listItemResponsive : ''}
            onClick={() => props.handleClick('TRAINING')}
          >
            <ListItemIcon className={window.innerWidth < 900 ? styles.listItemIconResponsive : ''}>
              <img
                className={changeStyleIcon(user?.isPatient ? '/training' : '/trainings')}
                src={iconTraining}
              />
            </ListItemIcon>
            <ListItemText
              className={changeStyleText(user?.isPatient ? '/training' : '/trainings')}
              primary={t('training')}
            />
          </ListItem>
        </OverlayTrigger>
      )}

      {/*    {user && !user.isPatient && (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="form-generator">{t('forms')}</Tooltip>}>
          <ListItem
            className={changeBackground(user?.isPatient ? '/form-generator' : '/form-generator')}
            button
            onClick={() => props.handleClick('FORM-GENERATOR')}>
            <ListItemIcon>
              <img src={pathname === '/form-generator' ? iconForms : iconFormInactive} />
            </ListItemIcon>
            <ListItemText className={styles.textMenu} primary={t('training')} />
          </ListItem>
        </OverlayTrigger>
      )}

      {user && user.isPatient && (
        <OverlayTrigger placement="right" overlay={<Tooltip id="user-forms">{t('forms')}</Tooltip>}>
          <ListItem
            className={changeBackground(user?.isPatient ? '/users/forms/' : '/users/forms/')}
            button
            onClick={() => props.handleClick('USER-FORMS')}>
            <ListItemIcon>
              <img src={pathname === '/users/forms/' ? iconForms : iconFormInactive} />
            </ListItemIcon>
            <ListItemText className={styles.textMenu} primary={t('training')} />
          </ListItem>
        </OverlayTrigger>
      )}*/}
    </List>
  )
}

export default ListItems
