import { Box, Grid, Tooltip } from '@mui/material'
import iconWearable from '../../assets/dashboard/device.svg'
import React, { useEffect, useState } from 'react'
import { emptyUserDTO, fromModel, User, UserDTO } from 'modules/users/models/User'
import { UserService } from '../../modules/users/services/UserService'
import { USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import styles from './dashboard.module.css'
import { useTranslation } from 'react-i18next'
import iconCalendar from '../../assets/dashboard/calendar.svg'
import iconChat from '../../assets/dashboard/chats.svg'
import iconTraining from '../../assets/dashboard/trainings.svg'
import iconPatient from '../../assets/dashboard/patient.svg'
import iconStatistics from '../../assets/dashboard/statistics.svg'
import iconEdit from '../../assets/dashboard/edit.svg'
import iconMedication from '../../assets/dashboard/treatments.svg'
import { Statistics } from '../statistics'
import {
  ROUTE_DASHBOARD_TRAINING,
  ROUTE_MESSENGER,
  ROUTE_TREATMENTS,
  ROUTE_USERS_CREATE,
} from '../../routes/routes-constants'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { AssignDeviceModal } from './AssignDeviceModal'
import { getContentContainer } from '../../container/statistics-module'
import { IWearableService, WEARABLE_SERVICE_KEY } from '../../modules/statistics'
import { Query, QueryParam } from '../../common/api/Query'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Wearable } from '../../modules/statistics/models/Wearable'
import { Table } from '../treatments/Table'
import { Skeleton } from '@mui/material'
import { IUserTrainingService, USER_TRAINING_SERVICE_KEY } from '../../modules/training'
import { UserTraining } from '../../modules/training/models/UserTraining'
import { getTrainingContainer } from '../../container/training-module'
import { ConversationService } from '../../modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from '../../modules/messenger'
import { getMessengerContainer } from '../../container/messenger-module'
import { Conversation, ConversationQuery } from '../../modules/messenger/models/Conversation'
import { AssignTrainingForm } from '../training/AssignTrainingForm'
import genericStyle from '../../common/utils/generic.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

type DashboardProps = {
  id?: string
}

const userContainer = getUserContainer()
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const wearableService = getContentContainer().get<IWearableService>(WEARABLE_SERVICE_KEY)
const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)
const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)

export const Dashboard = (props: DashboardProps) => {
  const { t } = useTranslation()
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [doctor, setDoctor] = useState<UserDTO>(emptyUserDTO())
  const [openModalAssignDevice, setOpenModalAssignDevice] = useState<boolean>(false)
  const [wearable, setWearable] = useState<ItemList<Wearable>>(emptyList())
  const [userTraining, setUserTraining] = useState<ItemList<UserTraining>>(emptyList())
  const [conversationCollection, setConversationCollection] = useState<Conversation[]>([])
  const [openModalAssignTraining, setOpenModalAssignTraining] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!props.id) {
      return
    }
    userService.getByID(props.id).subscribe((res) => {
      res && setUser(res)
      setIsLoading(false)
    })

    if (!user?.assignedID) return
    userService.getByID(user?.assignedID).subscribe((res) => {
      res && setDoctor(fromModel(res))
      setIsLoading(false)
    })
  }, [isLoading])

  useEffect(() => {
    if (!props.id) return
    wearableService
      .getFilteredList(
        new Query({
          query: [
            {
              name: 'userId',
              value: props.id || '',
            },
          ],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setWearable(res)
        setIsLoading(false)
      })
  }, [isLoading, openModalAssignDevice])

  useEffect(() => {
    if (!isLoading || !props?.id) {
      return
    }
    conversationsService
      .getFilteredItems(
        new Query({
          query: [new QueryParam<ConversationQuery>('userID', props.id || '')],
        })
      )
      .subscribe((res) => {
        setConversationCollection(res)
        setIsLoading(false)
      })
  }, [isLoading])

  /*useEffect(() => {
    if (!isLoading || !props?.id) {
      return
    }
    userTrainingService.getByID(props.id).subscribe((res) => {
      res && setUserTraining(fromModelUserTraining(res))
      setIsLoading(false)
    })
  }, [isLoading])*/
  useEffect(() => {
    userTrainingService
      .getFilteredList(
        new Query({
          query: [
            {
              name: 'userID',
              value: props.id || '',
            },
          ],
        })
      )
      .subscribe((res) => {
        setUserTraining(res)
      })
  }, [openModalAssignTraining])

  const handlerOpenModalAssignDevice = () => {
    setOpenModalAssignDevice(true)
  }

  const handlerCloseModalAssignDevice = () => {
    setOpenModalAssignDevice(false)
  }

  const handlerCloseModalAssignTraining = () => {
    setOpenModalAssignTraining(false)
  }

  const createTreatment = () => navigate(`${ROUTE_TREATMENTS}`, { state: { userID: props.id } })

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        className={genericStyle.pageContainer}
        style={{
          maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
          marginBottom: '95px',
          marginTop: 700,
        }}
      >
        <Box sx={{ marginTop: 50 }}>
          <Grid container spacing={3}>
            <Grid item xs lg={4}>
              <Box className={styles.containerBoxLittle}>
                <Box className={styles.titleBox}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p className={styles.titleText}>
                      {isLoading ? <Skeleton width={'10rem'} /> : t('devices')}
                    </p>
                    <p className={styles.textAmount}>
                      {isLoading ? <Skeleton width={'2rem'} /> : wearable.count}
                    </p>
                  </Box>
                  {isLoading ? (
                    <Skeleton variant="circular" width={40} height={40} />
                  ) : (
                    <img className={styles.imageBox} src={iconWearable} width={80} height={80} />
                  )}
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Box style={{ marginBottom: 8 }}>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <AppButton
                        label={wearable.count ? `${t('consultMac')}` : `${t('assign')}`}
                        theme={ButtonTheme.NewPrimary}
                        handler={handlerOpenModalAssignDevice}
                        type={'button'}
                      />
                    )}
                  </Box>
                  <Box className={styles.updateBox}>
                    <p className={styles.titleUpdateBox}>{t('updated')}</p>
                    <img
                      className={styles.imageCalendar}
                      src={iconCalendar}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs lg={4}>
              <Box className={styles.containerBoxLittle}>
                <Box className={styles.titleBox}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p className={styles.titleText}>
                      {isLoading ? <Skeleton width={'10rem'} /> : t('chats')}
                    </p>
                    <p className={styles.textAmount}>
                      {isLoading ? <Skeleton width={'2rem'} /> : conversationCollection.length}
                    </p>
                  </Box>
                  {isLoading ? (
                    <Skeleton variant="circular" width={40} height={40} />
                  ) : (
                    <img className={styles.imageBox} src={iconChat} width={80} height={80} />
                  )}
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Box style={{ marginBottom: 8 }}>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <AppButton
                        label={
                          conversationCollection.length > 0
                            ? `${t('seeConversation')}`
                            : `${t('openConversation')}`
                        }
                        theme={ButtonTheme.NewPrimary}
                        handler={() => navigate(ROUTE_MESSENGER)}
                        type={'button'}
                      />
                    )}
                  </Box>
                  <Box className={styles.updateBox}>
                    <p className={styles.titleUpdateBox}>{t('updated')}</p>
                    <img
                      className={styles.imageCalendar}
                      src={iconCalendar}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs lg={4}>
              <Box className={styles.containerBoxLittle}>
                <Box className={styles.titleBox}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p className={styles.titleText}>
                      {isLoading ? <Skeleton width={'10rem'} /> : t('training')}
                    </p>
                    <p className={styles.textAmount}>
                      {isLoading ? <Skeleton width={'2rem'} /> : userTraining.count}
                    </p>
                    <Box style={{ marginBottom: 8 }}>
                      {openModalAssignTraining && (
                        <AssignTrainingForm
                          open={openModalAssignTraining}
                          handleClose={handlerCloseModalAssignTraining}
                          id={props.id}
                        />
                      )}
                    </Box>
                  </Box>
                  {isLoading ? (
                    <Skeleton variant="circular" width={40} height={40} />
                  ) : (
                    <img className={styles.imageBox} src={iconTraining} width={80} height={80} />
                  )}
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Box style={{ marginBottom: 8 }}>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <>
                        {openMenuLeft ? (
                          <Button
                            className={styles.buttonTrainingOpenMenuLeft}
                            onClick={() =>
                              userTraining.count
                                ? navigate(`${ROUTE_DASHBOARD_TRAINING}/${user?.id}`)
                                : setOpenModalAssignTraining(true)
                            }
                          >
                            {userTraining.count ? `${t('seeTrainings')}` : `${t('assign')}`}
                          </Button>
                        ) : (
                          <AppButton
                            label={userTraining.count ? `${t('seeTrainings')}` : `${t('assign')}`}
                            theme={ButtonTheme.NewPrimary}
                            handler={() =>
                              userTraining.count
                                ? navigate(`${ROUTE_DASHBOARD_TRAINING}/${user?.id}`)
                                : setOpenModalAssignTraining(true)
                            }
                            type={'button'}
                          />
                        )}
                      </>
                    )}
                  </Box>
                  <Box className={styles.updateBox}>
                    <p className={styles.titleUpdateBox}>{t('updated')}</p>
                    <img
                      className={styles.imageCalendar}
                      src={iconCalendar}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs lg={12}>
              <Box className={styles.containerBoxStatistics}>
                <Box className={styles.boxMedications}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p className={styles.titleText}>{t('medication')}</p>
                  </Box>
                  <img className={styles.imageBox} src={iconMedication} width={70} height={70} />
                </Box>
                <Table id={user?.id} />
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box style={{ marginBottom: 8 }}>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <AppButton
                        label={t('addTreatment')}
                        theme={ButtonTheme.NewPrimary}
                        handler={createTreatment}
                        type={'button'}
                      />
                    )}
                  </Box>
                  <Box className={styles.updateBox}>
                    <p className={styles.titleUpdateBox}>{t('updated')}</p>
                    <img
                      className={styles.imageCalendar}
                      src={iconCalendar}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className={styles.containerBoxLarge}>
                <Box className={styles.titleBoxPatientStatistics}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p className={styles.titleText}>{t('patient')}</p>
                  </Box>
                  <img className={styles.imageBox} src={iconPatient} width={70} height={70} />
                </Box>
                <Box className={styles.infoPatientBox}>
                  <p className={styles.infoPatient}>{user?.firstName}</p>
                  <p className={styles.text}>{user?.lastName}</p>
                </Box>
                <Box className={styles.infoPatientBox}>
                  <p className={styles.infoPatient}>{user?.historyRecordNumber}</p>
                </Box>
                <Box className={styles.infoPatientBox}>
                  <p className={styles.infoPatient}>{user?.diagnosis}</p>
                </Box>
                <Box className={styles.infoPatientBox}>
                  <p className={styles.infoPatient}>{`${t('assignTo')} ${doctor.firstName}`}</p>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip
                    onClick={() => navigate(`${ROUTE_USERS_CREATE}/${user?.id}`)}
                    title={`${t('edit')}`}
                  >
                    <img
                      style={{ cursor: 'pointer' }}
                      className={styles.imageBox}
                      src={iconEdit}
                      width={38}
                      height={38}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={8}>
              <Box className={styles.containerBoxStatistics}>
                <Box className={styles.titleBoxPatientStatistics}>
                  <p style={{ marginLeft: 40 }} className={styles.titleText}>
                    {t('statistics')}
                  </p>
                  <img
                    style={{ marginRight: 40 }}
                    className={styles.imageBox}
                    src={iconStatistics}
                    width={70}
                    height={70}
                  />
                </Box>
                {props.id && (
                  <Statistics
                    dashboard={true}
                    stylesText={styles.statisticsText}
                    stylesHistorial={styles.styleHistorialButton}
                    styleInfoBox={styles.styleInfoBox}
                    id={props?.id}
                  />
                )}
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Box className={styles.updateBox}>
                    <p className={styles.titleUpdateBox}>{t('updated')}</p>
                    <img
                      className={styles.imageCalendar}
                      src={iconCalendar}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            {openModalAssignDevice && props.id && (
              <AssignDeviceModal
                open={openModalAssignDevice}
                handleClose={handlerCloseModalAssignDevice}
                idUser={props.id}
                wearableUser={wearable}
              />
            )}
          </Grid>
        </Box>
      </Box>
    </div>
  )
}
