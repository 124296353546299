import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useState } from 'react'
import {
  Box,
  Checkbox,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  SelectChangeEvent,
} from '@mui/material'
import style from './cron.module.css'
import { inputLabelClasses, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'
import dayjs from 'dayjs'

const themeStyles = makeStyles((theme) => ({
  input: {
    '&.Mui-focused': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#a9ba37 !important',
      },
    },
  },
  inputUnderline: {
    '&:after': {
      borderColor: '#a9ba37 !important',
    },
  },
  inputUnderSelect: {
    '&': { minWidth: '100% !important' },
    '&:after': {
      borderColor: '#a9ba37 !important',
    },
  },
  selectInput: {
    '&': {
      width: '29rem !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#a9ba37 !important',
    },
  },
  label: {
    [`&.${inputLabelClasses.shrink}`]: {
      color: '#a9ba37',
      marginTop: '-1%',
      backgroundColor: 'white',
      borderColor: '#a9ba37',
    },
  },
}))

export type CronItemProps = {
  handleChange: (s: string, sd: Date, fd: Date) => void
}

export function CronItemMockup(props: CronItemProps): JSX.Element {
  const { t } = useTranslation()
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [isDatePickerFinishOpen, setIsDatePickerFinishOpen] = useState<boolean>(false)

  const weekDaysNames = [
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
    t('sunday'),
  ]

  const [weekDay, setWeekDay] = useState<string[]>([])
  const [weekQuantity, setWeekQuantity] = useState<number>(1)
  const [monthQuantity, setMonthQuantity] = useState<number>(1)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [finishDate, setFinishDate] = useState<Date>(new Date())
  const [days, setDays] = useState<string>('*')
  const [months, setMonths] = useState<string>('*')
  const [monthsRanges, setMonthsRanges] = useState<string>('*')
  const [cron, setCron] = useState<string>('0 0 * * *')
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const [tabValue, setTabValue] = useState<number>(0)

  const StyledTab = styled(Tab)({
    '&.Mui-selected': {
      color: '#ADB84E',
    },
  })

  function handleChange(v: string) {
    const parse = Number.parseInt(v)
    console.log('entro al parse ' + parse)
    if (parse == 1) {
      setCron('0 0 * * 0')
      props.handleChange(cron, startDate, finishDate)
    }
    if (parse == 2) {
      setCron('0 0 1 * *')
      props.handleChange(cron, startDate, finishDate)
    }
    if (Number.isNaN(parse)) {
      setTabValue(0)
    } else {
      setTabValue(parse)
    }
  }

  const handleWeekDayChange = (event: SelectChangeEvent<string[]>) => {
    let daysAux = ''
    ;(event.target.value as string[]).forEach((e, i) => {
      if (i === 0) {
        daysAux += getWeekDayNumber(e)
      } else {
        daysAux += ',' + getWeekDayNumber(e)
      }
      setDays(daysAux)
    })

    setWeekDay(
      (event.target.value as string[]).sort(
        (a, b) => weekDaysNames.indexOf(a) - weekDaysNames.indexOf(b)
      )
    )

    setCron('0 0 * * ' + daysAux)
    console.log('el cron es : ' + cron)
    props.handleChange(cron, startDate, finishDate)
  }

  const getBeginning = (fin: number, daysQuantity: number) => {
    let inicio = fin + 1 + daysQuantity
    if (inicio > 31) {
      return (inicio % 31) + 1
    }
    return inicio
  }

  const getEnd = (inicio: number) => {
    if (inicio + 6 <= 31) {
      return inicio + 6
    } else {
      return ((inicio + 6) % 31) + 1
    }
  }

  function handleWeekQuantityChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    /*
    let parse = Number.parseInt(event.target.value)
    if (Number.isNaN(parse) || parse < 1) {
      parse = 1
    }
    setWeekQuantity(parse)

    let m = ' '
    if (parse === 1) {
      m += '*'
    } else {
      let daysQuantity = (parse - 1) * 7
      let total
      parse === 2 ? (total = 3) : (total = 2)
      let inicio = startDate.getDay()
      let fin = getEnd(inicio)

      for (let i = 0; i < total; i++) {
        if (i === 0) {
          m += inicio + '-' + fin
        } else {
          inicio = getBeginning(fin, daysQuantity)
          fin = getEnd(inicio)
          m += ',' + inicio + '-' + fin
        }
      }
    }
    setMonthsRanges(m)
*/

    //let cron = '00 09 ' + m + ' ' + months + ' ' + days
    setCron('00 09 * * ' + days)
    props.handleChange(cron, startDate, finishDate)
  }

  function handleMonthChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    let parse = Number.parseInt(event.target.value)
    if (Number.isNaN(parse) || parse < 1) {
      parse = 1
    }
    setMonthQuantity(parse)
    /* handleMonthRanges(parse, undefined, undefined)*/
    setCron('0 0 1 * * ')
    props.handleChange(cron, startDate, finishDate)
  }

  function handleMonthRanges( //se usa por defecto para el evento diario
    mQuantity: number | undefined,
    sDate: Date | undefined,
    fDate: Date | undefined
  ) {
    /*let mQuantity2
    mQuantity === undefined ? (mQuantity2 = monthQuantity) : (mQuantity2 = mQuantity)

    let sDate2
    sDate === undefined ? (sDate2 = startDate) : (sDate2 = sDate)

    let fDate2
    fDate === undefined ? (fDate2 = finishDate) : (fDate2 = fDate)

    const date = new Date()
    const currentMonth = date.getMonth() + 1
    let ms = ''
    const numberOfMonths = Math.abs(
      (fDate2.getFullYear() - sDate2.getFullYear()) * 12 +
        (fDate2.getMonth() - sDate2.getMonth()) -
        1
    )

    for (let i = currentMonth; i < currentMonth + numberOfMonths; i += mQuantity2) {
      if (i === currentMonth) {
        ms += i
      } else {
        if (i > 12) {
          ms += ',' + (i % 12)
        } else {
          ms += ',' + i
        }
      }
      setMonths(ms)
    }
*/
    setCron('00 09 * * *')
    props.handleChange(cron, startDate, finishDate)
  }

  function handleStartDateChange(event: any) {
    if (event) {
      let date = event.toDate()
      console.log('inicio : ' + cron)
      setStartDate(() => date)
      props.handleChange(cron, date, finishDate)
    }
  }

  function handleFinishDateChange(event: any) {
    if (event) {
      let date = event.toDate()
      console.log(cron)
      setFinishDate(() => date)
      props.handleChange(cron, startDate, date)
    }
  }

  const classes = themeStyles()

  const getWeekDayNumber = (day: string) => {
    switch (day) {
      case t('monday'):
        return 1
      case t('tuesday'):
        return 2

      case t('wednesday'):
        return 3

      case t('thursday'):
        return 4

      case t('friday'):
        return 5

      case t('saturday'):
        return 6

      case t('sunday'):
        return 0
    }
  }

  /*
  const getWeekDayNumber = (day: string) => {
    const dayWeek = startDate.getDay()
    switch (day) {
      case t('monday'):
        let a = 0 - dayWeek
        if (a < 0) {
          a += 7
        }
        return a
      case t('tuesday'):
        let b = 1 - dayWeek
        if (b < 0) {
          b += 7
        }
        return b

      case t('wednesday'):
        let c = 2 - dayWeek
        if (c < 0) {
          c += 7
        }
        return c

      case t('thursday'):
        let d = 3 - dayWeek
        if (d < 0) {
          d += 7
        }
        return d

      case t('friday'):
        let e = 4 - dayWeek
        if (e < 0) {
          e += 7
        }
        return e

      case t('saturday'):
        let f = 5 - dayWeek
        if (f < 0) {
          f += 7
        }
        return f

      case t('sunday'):
        let g = 6 - dayWeek
        if (g < 0) {
          g += 7
        }
        return g
    }
  }

 */

  const CalendarIcon = () => <span style={{ display: 'none' }}></span>

  return (
    <>
      <Box className={style.mockupCronContainer} display="flex">
        <Box className={style.mockupCronTypeContainer} display="flex">
          <RadioGroup>
            <ListItem>
              <Tabs
                TabIndicatorProps={{ style: { background: '#a9ba37' } }}
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={(_, value) => handleChange(value)}
                aria-label="Recurrence options"
              >
                <StyledTab
                  style={{ padding: 0, fontFamily: 'Montserrat-SemiBold' }}
                  label={t('daily')}
                />
                <StyledTab
                  style={{ padding: 0, fontFamily: 'Montserrat-SemiBold' }}
                  label={t('weekly')}
                />
                <StyledTab
                  style={{ padding: 0, fontFamily: 'Montserrat-SemiBold' }}
                  label={t('monthly')}
                />
              </Tabs>
            </ListItem>
          </RadioGroup>
        </Box>
        <Box style={{ margin: 'auto' }}>
          <p style={{ fontFamily: 'Montserrat' }} hidden={tabValue !== 0}>
            {t('eventDaily')}
          </p>
          <List hidden={tabValue !== 1}>
            <ListItem>
              <Box>
                {t('repeatEach')}
                <TextField
                  id={'week'}
                  key={'week'}
                  InputProps={{ inputProps: { min: 1, max: 4 } }}
                  style={{ margin: '0 10px', width: '60px', fontFamily: 'Montserrat' }}
                  value={weekQuantity}
                  onChange={handleWeekQuantityChange}
                  name="week"
                  type={'number'}
                />
                {t('weeks')}
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                <Select
                  style={{ width: '240px' }}
                  labelId="select-week-label"
                  id="select-week"
                  multiple
                  value={weekDay}
                  onChange={handleWeekDayChange}
                  input={<Input className={style.inputWeek} />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  className={style.mockupSelect}
                >
                  {weekDaysNames.map((weekDayName) => (
                    <MenuItem key={weekDayName} value={weekDayName}>
                      <Checkbox checked={weekDay.indexOf(weekDayName) > -1} />
                      <ListItemText primary={weekDayName} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </ListItem>
          </List>
          <Box hidden={tabValue !== 2}>
            <span style={{ fontFamily: 'Montserrat' }}>{t('repeatEach')}</span>
            <Input
              style={{ margin: '0 10px', width: '60px' }}
              className={classes.inputUnderline}
              id={'month'}
              key={'month'}
              value={monthQuantity}
              onChange={handleMonthChange}
              name="week"
              type="number"
            />
            {t('months')}
          </Box>
        </Box>
        <Box className={style.mockupRepeatingContainer}>
          <List>
            <ListItem>
              <InputLabel style={{ fontFamily: 'Montserrat' }}>{t('rangeOfRepetition')}</InputLabel>
            </ListItem>
            <ListItem>
              <span style={{ marginRight: 14, fontSize: 12, fontFamily: 'Montserrat' }}>
                {t('comienzo')}
              </span>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  key={'startDate'}
                  format={'DD/MM/YYYY HH:mm'}
                  open={isDatePickerOpen}
                  onOpen={() => setIsDatePickerOpen(true)}
                  onClose={() => setIsDatePickerOpen(false)}
                  onChange={(e) => handleStartDateChange(e)}
                  value={dayjs(startDate)}
                  minDate={dayjs(new Date())}
                  label={''}
                  onError={(reason) => {
                    switch (reason) {
                      case 'invalidDate':
                        setDateTimePickerError(t('invalidDateMessage'))
                        break
                      case 'minDate':
                        setDateTimePickerError(t('minDateMessage'))
                        break
                    }
                  }}
                  slotProps={{
                    textField: {
                      id: 'startDate',
                      size: 'small',
                      InputProps: {
                        sx: {
                          '& fieldset': {
                            borderRadius: 32,
                            border: '1px solid #E8E7EC',
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                          '& .MuiOutlinedInput-root': {
                            border: 'none',
                            '&.Mui-focused fieldset': {
                              borderColor: '#ADB84E',
                            },
                          },
                        },
                        onClick: () => setIsDatePickerOpen(true),
                      },
                    },
                  }}
                  slots={{
                    openPickerIcon: CalendarIcon,
                  }}
                />
              </LocalizationProvider>
            </ListItem>
            <ListItem>
              <span style={{ marginRight: 14, fontSize: 12, fontFamily: 'Montserrat' }}>
                {t('finishAt')}
              </span>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  open={isDatePickerFinishOpen}
                  onOpen={() => setIsDatePickerFinishOpen(true)}
                  onClose={() => setIsDatePickerFinishOpen(false)}
                  key={'finishDate'}
                  format={'DD/MM/YYYY HH:mm'}
                  onChange={(e) => handleFinishDateChange(e)}
                  label={''}
                  value={dayjs(finishDate)}
                  minDate={dayjs(startDate)}
                  onError={(reason) => {
                    switch (reason) {
                      case 'invalidDate':
                        setDateTimePickerError(t('invalidDateMessage'))
                        break
                      case 'minDate':
                        setDateTimePickerError(t('minDateMessage'))
                        break
                    }
                  }}
                  slotProps={{
                    textField: {
                      id: 'finishDate',
                      size: 'small',
                      InputProps: {
                        sx: {
                          '& fieldset': {
                            borderRadius: 32,
                            border: '1px solid #E8E7EC',
                            fontFamily: 'Montserrat',
                            fontSize: '13px',
                          },
                          '& .MuiInputBase-root': { fontFamily: 'Montserrat', fontSize: '13px' },
                          '& .MuiOutlinedInput-root': {
                            border: 'none',
                            '&.Mui-focused fieldset': {
                              borderColor: '#ADB84E',
                            },
                          },
                        },
                        onClick: () => setIsDatePickerFinishOpen(true),
                      },
                    },
                  }}
                  slots={{
                    openPickerIcon: CalendarIcon,
                  }}
                />
              </LocalizationProvider>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  )
}
