import Button from '@mui/material/Button'
import styles from './AppButton.module.css'
import React from 'react'
import { capitalize } from '../../common/utils/strings'

export enum ButtonTheme {
  NewPrimary,
  NewPrimaryResponsive,
  NewSecondary,
  BasicPrimary,
  BasicSecondary,
  BasicTrasparent,
  addPatientButton,
  addResourceButton,
  addNewEventButton,
  buttonLogin,
}

type AppButtonProps = {
  theme: ButtonTheme
  label?: string
  labelOptions?: { count: number }
  handler: (e?: any) => void
  startIcon?: string
  endIcon?: string
  span?: boolean
  type: 'button' | 'submit' | 'reset'
  vertical?: boolean
  fullWidth?: boolean
}

export function AppButton(props: AppButtonProps) {
  return (
    <Button
      className={getClassName(props.theme)}
      color={getColor(props.theme)}
      sx={{
        ...(props.vertical && {
          '& .MuiButton-label': {
            // Estilos para la etiqueta del botón en modo vertical
            flexDirection: 'column',
            padding: '5px',
            width: '90%',
            fontFamily: 'Poppins, sans-serif',
          },
          '& .MuiButton-startIcon': {
            // Estilos para el icono de inicio del botón en modo vertical
            marginLeft: '0',
            marginRight: '0',
          },
        }),
      }}
      variant="contained"
      size="large"
      fullWidth={props.fullWidth}
      disableElevation={true}
      type={props.type}
      component={props.span ? 'span' : 'button'}
      onClick={props.handler}
      endIcon={props.endIcon && <img src={props.endIcon} alt="endIcon" />}
      startIcon={props.startIcon && <img src={props.startIcon} alt="startIcon" />}
    >
      {props.label ? capitalize(props.label) : ''}
    </Button>
  )
}

function getClassName(theme: ButtonTheme) {
  let style = styles.button
  switch (theme) {
    case ButtonTheme.NewPrimary:
      style += ' ' + styles.newPrimary
      break
    case ButtonTheme.NewSecondary:
      style += ' ' + styles.newSecondary
      break
    case ButtonTheme.BasicTrasparent:
      style += ' ' + styles.transparent
      break
    case ButtonTheme.NewPrimaryResponsive:
      style += ' ' + styles.newPrimaryResponsive
      break
    case ButtonTheme.addPatientButton:
      style += ' ' + styles.addPatientButton
      break
    case ButtonTheme.addResourceButton:
      style += ' ' + styles.addResourceButton
      break
    case ButtonTheme.addNewEventButton:
      style += ' ' + styles.addNewEventButton
      break
    case ButtonTheme.buttonLogin:
      style += ' ' + styles.buttonLogin
      break
    default:
      break
  }

  return style
}

function getColor(theme: ButtonTheme) {
  const name = ButtonTheme[theme].toLowerCase()
  if (name.includes('primary')) {
    return 'primary'
  }
  if (name.includes('secondary')) {
    return 'secondary'
  }
  return 'inherit'
}
