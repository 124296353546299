import React, { useEffect, useState } from 'react'
import { NewsWall } from '../pages/newsWall'
import { Permission } from '../common/permission'
import {
  ROUTE_ARTICLE,
  ROUTE_ARTICLES,
  ROUTE_ARTICLES_FORM,
  ROUTE_ARTICLES_FORM_EDIT,
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_FORM,
  ROUTE_CALENDAR_FORM_EDIT,
  ROUTE_CALENDAR_ID,
  ROUTE_CONFIGURATION,
  ROUTE_DASHBOARD_ID,
  ROUTE_DASHBOARD_TRAINING_ID,
  ROUTE_FORM_GENERATOR,
  ROUTE_FORM_GENERATOR_ASSIGNTO,
  ROUTE_FORM_GENERATOR_FORM_CREATE,
  ROUTE_FORM_GENERATOR_FORM_EDIT,
  ROUTE_FORM_GENERATOR_FORM_RESULTS,
  ROUTE_FORM_GENERATOR_USERS,
  ROUTE_MESSENGER,
  ROUTE_MESSENGER_ID,
  ROUTE_MOBILE_MENU,
  ROUTE_NEWS_WALL,
  ROUTE_NOTIFICATIONS_CONFIG,
  ROUTE_PATIENT_FORMS,
  ROUTE_PATIENT_FORMS_VIEW,
  ROUTE_PATIENTS,
  ROUTE_RESOURCES,
  ROUTE_RESOURCES_FORM_EDIT,
  ROUTE_RESOURCES_ID,
  ROUTE_RESOURCES_SHARED_WITH_ME,
  ROUTE_RESOURCES_SHARED_WITH_ME_ID,
  ROUTE_ROLE_PERMISSIONS,
  ROUTE_STATISTICS,
  ROUTE_STATISTICS_ID,
  ROUTE_TRAINING,
  ROUTE_TRAINING_ID,
  ROUTE_TRAININGS,
  ROUTE_TRAININGS_CREATE,
  ROUTE_TRAININGS_ID,
  ROUTE_TREATMENTS,
  ROUTE_TREATMENTS_ID,
  ROUTE_USER_FORMS,
  ROUTE_USER_PROFILE,
  ROUTE_USERS_CREATE,
  ROUTE_USERS_CREATE_ID,
  ROUTE_PRIVACY_POLICY,
  ROUTE_COOKIES_POLICY,
  ROUTE_CALENDAR_EXERCISES,
  ROUTE_CALENDAR_EXERCISES_ID,
} from './routes-constants'
import { Messenger } from '../pages/messenger'
import { Calendar } from '../pages/calendar'
import { ArticlesForm } from '../pages/articles-form'
import { ArticlesView } from '../pages/articles-view'
import { FormGenerator } from '../pages/form-generator'
import { FormGeneratorTable } from '../pages/form-generator-table'
import {
  ARTICLES_TITLE,
  CALENDAR_EXERCISE_TITLE,
  CALENDAR_TITLE,
  CONFIGURATION_TITLE,
  COOKIES_POLICY_TITLE,
  CREATE_PATIENT_TITLE,
  CREATE_TRAINING_TITLE,
  DASHBOARD_TITLE,
  EDIT_PATIENT_TITLE,
  FORM_GENERATOR_ASSIGNTO_TITLE,
  FORM_GENERATOR_EDITOR_TITLE,
  FORM_GENERATOR_FORM_CREATE_TITLE,
  FORM_GENERATOR_RESULTS_TITLE,
  FORM_GENERATOR_TITLE,
  FORM_GENERATOR_USERS_TITLE,
  FORMS_TITLE,
  MESSENGER_TITLE,
  MOBILE_MENU_TITLE,
  NEWSWALL_TITLE,
  PATIENT_FORM_VIEW_TITLE,
  PATIENTS_FORMS_TITLE,
  PRIVACY_POLICY_TITLE,
  RESOURCES_NOTIFICATIONS_CONFIG_TITLE,
  RESOURCES_SHARED_WITH_ME_TITLE,
  RESOURCES_TITLE,
  ROLE_PERMISSIONS_TITLE,
  STATISTICS_TITLE,
  TRAINING_TITLE,
  TREATMENT_TITLE,
  USER_PROFILE_TITLE,
  USERS_TITLE,
} from './title-constants'
import { FormGeneratorResults } from '../pages/form-generator-results'
import { Configuration } from '../pages/configuration'
import { CalendarForm } from '../pages/calendar-form'
import { PatientFormsTable } from '../pages/patient-forms-list'
import { PatientFormView } from '../pages/patient-forms-view'
import { Resources } from '../pages/resources'
import { ResourcesForm } from '../pages/resources-form'
import { NotificationsConfig } from '../pages/notifications-config/NotificationsConfig'
import { RolePermissions } from '../pages/role-permissions'
import { ICircleService } from '../modules/users/services/CircleService'
import { User } from '../modules/users/models/User'
import { EditParticipant } from '../pages/users'
import { FormGeneratorUsers } from '../pages/form-generator-users'
import { UserProfile } from '../pages/user-profile'
import { MobileMenu } from '../pages/mobile-menu'
import { getUserContainer } from '../container/user-module'
import { CIRCLE_SERVICE_KEY, LOGGED_USER_SERVICE_KEY } from '../modules/users/container'
import { LoggedUserService } from '../modules/users/services/LoggedUserService'
import { ArticlesTable } from '../pages/articles-table'
import { UsersTable } from '../pages/users-table'
import { Statistics } from '../pages/statistics'
import { Training } from '../pages/training'
import { TrainingView } from '../pages/training-view'
import { Dashboard } from '../pages/dashboard'
import { TreatmentsForm } from '../pages/treatments-form'
import { AllTrainings } from '../pages/trainings'
import { TrainingsView } from '../pages/trainings-view/TrainingsView'
import { CreateTraining } from '../pages/training-form'
import { FormGeneratorAssignTo } from 'pages/form-generator-assignTo'
import { PrivacyPolicy } from '../pages/privacy-policy'
import { CookiesPolicy } from '../pages/cookies-policy'
import { CalendarExercise } from '../pages/calendar-exercise'
import { Route, Routes } from 'react-router-dom'

export type RouteProps = {
  title?: string
  subtitle?: string
  id?: string
}

const circleService = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const AppRouter: React.FC = () => {
  const [circle, setCircle] = useState<User | undefined>(circleService.getActive())

  useEffect(() => {
    circleService.getActiveObservable().subscribe((c) => setCircle(c))
  }, [circle])

  const generalRoutes = (
    <>
      <Route
        path={ROUTE_FORM_GENERATOR_FORM_EDIT}
        element={<FormGenerator title={FORM_GENERATOR_EDITOR_TITLE} />}
      />
      <Route
        path={ROUTE_FORM_GENERATOR_FORM_RESULTS}
        element={<FormGeneratorResults title={FORM_GENERATOR_RESULTS_TITLE} />}
      />

      {loggedUserService.userCan(Permission.createFormTemplates) && (
        <Route
          path={ROUTE_FORM_GENERATOR}
          element={<FormGeneratorTable title={FORM_GENERATOR_TITLE} />}
        />
      )}

      <Route
        path={ROUTE_FORM_GENERATOR_USERS}
        element={<FormGeneratorUsers title={FORM_GENERATOR_USERS_TITLE} />}
      />
      <Route path={ROUTE_ARTICLE} element={<ArticlesView />} />

      <Route path={ROUTE_ARTICLES_FORM_EDIT} element={<ArticlesForm title={ARTICLES_TITLE} />} />
      <Route path={ROUTE_ARTICLES_FORM} element={<ArticlesForm title={ARTICLES_TITLE} />} />
      <Route path={ROUTE_ARTICLES} element={<ArticlesTable title={ARTICLES_TITLE} />} />
      <Route path={ROUTE_PATIENTS} element={<UsersTable title={USERS_TITLE} />} />
      <Route path={ROUTE_CONFIGURATION} element={<Configuration title={CONFIGURATION_TITLE} />} />

      {loggedUserService.userCan(Permission.editViewRolePermissions) && (
        <Route
          path={ROUTE_ROLE_PERMISSIONS}
          element={<RolePermissions title={ROLE_PERMISSIONS_TITLE} />}
        />
      )}
      <Route path={ROUTE_MOBILE_MENU} element={<MobileMenu title={MOBILE_MENU_TITLE} />} />

      <Route path={ROUTE_USERS_CREATE} element={<EditParticipant title={CREATE_PATIENT_TITLE} />} />
      <Route
        path={ROUTE_USERS_CREATE_ID}
        element={<EditParticipant title={EDIT_PATIENT_TITLE} />}
      />
      <Route path={ROUTE_USER_PROFILE} element={<UserProfile title={USER_PROFILE_TITLE} />} />
      <Route path={ROUTE_NEWS_WALL} element={<NewsWall title={NEWSWALL_TITLE} />} />
      <Route path={ROUTE_MESSENGER} element={<Messenger title={MESSENGER_TITLE} />} />
      <Route path={ROUTE_MESSENGER_ID} element={<Messenger title={MESSENGER_TITLE} />} />
      <Route path={ROUTE_CALENDAR} element={<Calendar title={CALENDAR_TITLE} />} />
      <Route path={ROUTE_CALENDAR_ID} element={<Calendar title={CALENDAR_TITLE} />} />
      <Route
        path={ROUTE_CALENDAR_EXERCISES}
        element={<CalendarExercise title={CALENDAR_EXERCISE_TITLE} />}
      />
      <Route
        path={ROUTE_CALENDAR_EXERCISES_ID}
        element={<CalendarExercise title={CALENDAR_EXERCISE_TITLE} />}
      />

      <Route path={ROUTE_CALENDAR_FORM} element={<CalendarForm title={CALENDAR_TITLE} />} />

      <Route path={ROUTE_CALENDAR_FORM_EDIT} element={<CalendarForm title={CALENDAR_TITLE} />} />
      <Route
        path={ROUTE_PATIENT_FORMS_VIEW}
        element={<PatientFormView title={PATIENT_FORM_VIEW_TITLE} />}
      />
      <Route
        path={ROUTE_PATIENT_FORMS}
        element={<PatientFormsTable title={PATIENTS_FORMS_TITLE} />}
      />
      <Route path={ROUTE_RESOURCES} element={<Resources title={RESOURCES_TITLE} />} />
      <Route path={ROUTE_RESOURCES_ID} element={<Resources title={RESOURCES_TITLE} />} />
      <Route
        path={ROUTE_RESOURCES_SHARED_WITH_ME}
        element={<Resources title={RESOURCES_SHARED_WITH_ME_TITLE} />}
      />
      <Route
        path={ROUTE_RESOURCES_SHARED_WITH_ME_ID}
        element={<Resources title={RESOURCES_SHARED_WITH_ME_TITLE} />}
      />
      <Route path={ROUTE_RESOURCES_FORM_EDIT} element={<ResourcesForm title={RESOURCES_TITLE} />} />

      {loggedUserService.userCan(Permission.configureNotifications) && (
        <Route
          path={ROUTE_NOTIFICATIONS_CONFIG}
          element={<NotificationsConfig title={RESOURCES_NOTIFICATIONS_CONFIG_TITLE} />}
        />
      )}

      <Route path={ROUTE_STATISTICS} element={<Statistics title={STATISTICS_TITLE} />} />
      <Route path={ROUTE_STATISTICS_ID} element={<Statistics title={STATISTICS_TITLE} />} />
      <Route path={ROUTE_TRAINING} element={<Training title={TRAINING_TITLE} />} />
      <Route path={ROUTE_DASHBOARD_TRAINING_ID} element={<Training title={TRAINING_TITLE} />} />
      <Route path={ROUTE_TRAINING_ID} element={<TrainingView />} />
      <Route path={ROUTE_TRAININGS} element={<AllTrainings title={TRAINING_TITLE} />} />
      <Route path={ROUTE_TRAININGS_ID} element={<TrainingsView />} />
      <Route
        path={ROUTE_TRAININGS_CREATE}
        element={<CreateTraining title={CREATE_TRAINING_TITLE} />}
      />
      <Route path={ROUTE_DASHBOARD_ID} element={<Dashboard title={DASHBOARD_TITLE} />} />

      <Route path={ROUTE_TREATMENTS} element={<TreatmentsForm title={TREATMENT_TITLE} />} />
      <Route path={ROUTE_TREATMENTS_ID} element={<TreatmentsForm title={TREATMENT_TITLE} />} />
      <Route
        path={ROUTE_FORM_GENERATOR}
        element={<FormGeneratorTable title={FORM_GENERATOR_TITLE} />}
      />
      <Route
        path={ROUTE_FORM_GENERATOR_FORM_CREATE}
        element={<FormGenerator title={FORM_GENERATOR_FORM_CREATE_TITLE} />}
      />
      <Route
        path={ROUTE_FORM_GENERATOR_ASSIGNTO}
        element={<FormGeneratorAssignTo title={FORM_GENERATOR_ASSIGNTO_TITLE} />}
      />
      <Route path={ROUTE_USER_FORMS} element={<PatientFormsTable title={FORMS_TITLE} />} />

      <Route path={ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy title={PRIVACY_POLICY_TITLE} />} />
      <Route path={ROUTE_COOKIES_POLICY} element={<CookiesPolicy title={COOKIES_POLICY_TITLE} />} />
    </>
  )

  return <Routes>{generalRoutes}</Routes>
}
